import React from 'react'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import TermsModal from '../components/TermsModal/TermsModal'

export default function IndexLayout() {

  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>
      <TermsModal/>
      <Toaster position='top-left' toastOptions={{duration: 4000}}/>
    </>
  )
}
