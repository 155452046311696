import React, { useEffect, useState } from 'react'
import './profileDisplay.css'
import { Link as Anchor } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import toast from 'react-hot-toast';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AWS from 'aws-sdk'
import ProfileImages from '../ProfileImages/ProfileImages';
import { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import diamondVip from '../../images/diamante-vip.webp'
import diamond from '../../images/diamante.webp'
import platinum from '../../images/platino.webp'
import gold from '../../images/oro.webp'
import verified from '../../images/verificado.webp'
import { LoadStart, LoadRemove } from '../Loading'

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: grey[300];
    background: grey[900];
    border: 1px solid grey[700];
    box-shadow: 0px 2px 2px 'dark';

    &:hover {
      border-color: blue[400];
    }

    &:focus {
      border-color: blue[400];
      box-shadow: 0 0 0 3px 'dark';
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

const s3 = new AWS.S3({
    region: "sa-east-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export default function ProfileDisplay() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [segmentos, setSegmentos] = useState([])
    const [userProperties, setUserProperties] = useState({})
    const [expanded, setExpanded] = useState(false);
    const imageInput = useRef()
    const videoInput = useRef()
    const [openImage, setOpenImage] = useState(false);
    const handleOpenImage = () => setOpenImage(true);
    const handleCloseImage = () => setOpenImage(false);
    const [openVideo, setOpenVideo] = useState(false);
    const handleOpenVideo = () => setOpenVideo(true);
    const handleCloseVideo = () => setOpenVideo(false);
    const token = localStorage.getItem('token')
    let headers = { headers: { 'Authorization': `Bearer ${token}` } }
    const [reload, setReload] = useState(false)
    const [wantDelete, setWantDelete] = useState(false)
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [comments, setComments] = useState([])
    const user = JSON.parse(localStorage.getItem('user'))

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    function getCity(segmentosUrl) {
        var profileUrl = segmentosUrl.split('/').map(function (segmento) {
            var palabras = segmento.split('-');
            palabras = palabras.map(function (palabra) {
                return palabra.charAt(0).toUpperCase() + palabra.slice(1);
            });
            return palabras.join(' ');
        });
        setSegmentos(profileUrl)
    }

    function getProperties(user) {
        const CABELLO = user.hair_color
        const OJOS = user.eyes_color
        const DEPILACIÓN = user.depilation
        const CUERPO = user.body_type
        const BUSTO = user.tits_type
        const COLA = user.ass_type
        const HORARIO = user.work_hours

        const properties = {
            años: user.age,
            altura: user.height,
            cm: user.dotation,
            CABELLO,
            OJOS,
            DEPILACIÓN,
            CUERPO,
            BUSTO,
            COLA,
            HORARIO
        }
        const propertiesList = Object.entries(properties)
        setUserProperties(propertiesList)
    }

    async function getData() {
        LoadStart()
        try {
            let url = 'https://encantos-ardientes.onrender.com/post/me'
            const res = await axios.get(url, headers)
            localStorage.setItem('post', JSON.stringify({
                ...res.data.post
            }))
            setUserData(res.data.post)
            getCity(res.data.post.city)
            getProperties(res.data.post)
            setImages(res.data.postImages)
            setVideos(res.data.postVideos)
            localStorage.setItem(
                "user",
                JSON.stringify({
                    id: res.data.user._id,
                    name: res.data.user.name,
                    email: res.data.user.email,
                    photo: res.data.user.photo,
                    coins: res.data.user.coins,
                    admin: res.data.user.is_admin,
                    has_posted: res.data.user.has_posted,
                    loginAt: res.data.user.loginAt
                })
            );
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleDelete() {
        LoadStart()
        try {
            const isConfirmed = window.confirm('¿Estás seguro de que quieres eliminar esto?');
            if (isConfirmed) {
                const userStorage = JSON.parse(localStorage.getItem('user'))
                const url = 'https://encantos-ardientes.onrender.com/post/delete'
                const params = {
                    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                    Key: userData.aws_key,
                };
                await s3.deleteObject(params).promise();
                if (userData.video) {
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: userData.video_aws_key,
                    };
                    await s3.deleteObject(params).promise();
                }
                const res = await axios.delete(url, headers)
                toast.success(res.data.message);
                localStorage.setItem('user', JSON.stringify({
                    ...userStorage,
                    has_posted: false
                }))
                navigate('/')
            }
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function uploadImage() {
        LoadStart()
        handleCloseImage()
        try {
            const files = imageInput.current.files
            let url = 'https://encantos-ardientes.onrender.com/postimage/create'
            for (let i = 0; i < files.length; i++) {

                if (files[i].type.includes('/png') || files[i].type.includes('/jpg') || files[i].type.includes('/jpeg') || files[i].type.includes('/webp')) {
                    const ahora = new Date();
                    const dia = ahora.getDate().toString().padStart(2, '0');
                    const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Nota: los meses son indexados desde 0
                    const anio = ahora.getFullYear().toString().slice(-2);
                    const hora = ahora.getHours().toString().padStart(2, '0');
                    const minutos = ahora.getMinutes().toString().padStart(2, '0');
                    const segundos = ahora.getSeconds().toString().padStart(2, '0');
                    const resultado = `${dia}${mes}${anio}${hora}${minutos}${segundos}`;
                    const photo = files[i];
                    const photoName = `${resultado}${files[i].name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: photoName,
                        Body: photo,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    const imageData = {
                        image: responseS3.Location,
                        aws_key: photoName
                    }
                    const res = await axios.post(url, imageData, headers)
                    toast.success(res.data.message)
                } else {
                    toast.error('Tipo de archivo no permitido')
                }
            }
            setReload(!reload)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function uploadVideo() {
        LoadStart()
        handleCloseVideo()
        try {
            const files = videoInput.current.files
            let url = 'https://encantos-ardientes.onrender.com/postvideo/create'
            for (let i = 0; i < files.length; i++) {

                if (files[i].type.includes('/png') || files[i].type.includes('/mp4') || files[i].type.includes('/mov') || files[i].type.includes('/3gp') || files[i].type.includes('/avi')) {
                    const ahora = new Date();
                    const dia = ahora.getDate().toString().padStart(2, '0');
                    const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Nota: los meses son indexados desde 0
                    const anio = ahora.getFullYear().toString().slice(-2);
                    const hora = ahora.getHours().toString().padStart(2, '0');
                    const minutos = ahora.getMinutes().toString().padStart(2, '0');
                    const segundos = ahora.getSeconds().toString().padStart(2, '0');
                    const resultado = `${dia}${mes}${anio}${hora}${minutos}${segundos}`;
                    const video = files[i];
                    const videoName = `${resultado}${files[i].name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: videoName,
                        Body: video,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    const videoData = {
                        video: responseS3.Location,
                        aws_key: videoName
                    }
                    const res = await axios.post(url, videoData, headers)
                    toast.success(res.data.message)
                } else {
                    toast.error('Tipo de archivo no permitido')
                }
            }
            setReload(!reload)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    const copiarAlPortapapeles = () => {
        // Crear un elemento de textarea temporal
        const textarea = document.createElement('textarea');
        // Asignar el texto a copiar al contenido del textarea
        textarea.value = `http://localhost:3000/anuncio/${userData.nameURL}`;
        // Asegurarse de que el textarea no sea visible
        textarea.style.position = 'fixed';
        textarea.style.opacity = 0;
        // Agregar el textarea al DOM
        document.body.appendChild(textarea);
        // Seleccionar el contenido del textarea
        textarea.select();

        try {
            // Intentar copiar el contenido al portapapeles
            document.execCommand('copy');
            toast.success('Link copiado al portapapeles');
        } catch (err) {
            toast.error('Error al intentar copiar al portapapeles:', err);
        } finally {
            // Eliminar el textarea temporal
            document.body.removeChild(textarea);
        }
    };

    function goToZone(e) {
        if (userData.city) {
            let string1 = userData.city;
            let string2 = e.target.text.toLowerCase().replace(' ', '-');
            var partes = string1.split("/");
            var index = partes.indexOf(string2);
            var resultado = partes.slice(0, index + 1).join("/");
            let userCategory = userData.category.toLowerCase()
            if (userCategory === 'hombre') {
                userCategory = 'hombres'
            }
            if (userCategory === 'mujer') {
                userCategory = 'mujeres'
            }
            if (string2 === 'argentina') {
                navigate(`/${userCategory}`)
            } else {
                navigate(`/${userCategory}/${resultado}`)
            }
        }
    }

    async function getComments() {
        try {
            let url = `https://encantos-ardientes.onrender.com/comment/${userData._id}`
            const res = await axios.get(url)
            setComments(res.data.comments)
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    useEffect(() => {
        getData()
    }, [reload])

    useEffect(() => {
        if (userData._id) {
            getComments()
        }
    }, [userData])

    async function handleEditComment(e) {
        LoadStart()
        const element = e.target.localName
        let id = e.target.id
        if (element === 'path') {
            id = e.target.parentElement.id
        }
        try {
            const url = `https://encantos-ardientes.onrender.com/comment/edit/${id}`
            const textArea = document.getElementById(`textArea${id}`)
            const text = textArea.value
            const data = {
                text
            }
            const res = await axios.put(url, data, headers)
            toast.success(res.data.message)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleDeleteComment(e) {
        LoadStart()
        const element = e.target.localName
        let id = e.target.id
        if (element === 'path') {
            id = e.target.parentElement.id
        }
        try {
            const url = `https://encantos-ardientes.onrender.com/comment/delete/${id}`
            const res = await axios.delete(url, headers)
            toast.success(res.data.message)
            getComments()
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    return (
        <main className='profileDisplayContainer'>
            <div className='urlCardContainer'>
                {
                    segmentos?.map((urlSection, i) => {
                        return <section className='urlCard' key={i}>
                            <Anchor to='#' className='zonesUrl' onClick={goToZone}>{urlSection}</Anchor>
                            {segmentos.length - 1 > i && <KeyboardArrowRightIcon fontSize='5px' />}
                        </section>
                    })
                }
            </div>
            {
                window.innerWidth >= 767 ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                        <div className='profileDataContainer'>
                            {userData.tier === 5 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE VIP</h2><img className='categoryImg' src={diamondVip} alt='diamante'></img></div>}
                            {userData.tier === 4 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE</h2><img className='categoryImg' src={diamond} alt='diamante'></img></div>}
                            {userData.tier === 3 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>PLATINO</h2><img className='categoryImg' src={platinum} alt='platino'></img></div>}
                            {userData.tier === 2 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>ORO</h2><img className='categoryImg' src={gold} alt='oro'></img></div>}
                            {(userData.tier === 1 && (userData.category === 'Trans' || userData.category === 'Mujer')) && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>VERIFICADA</h2><img className='categoryImg' src={verified} alt='verificadas'></img></div>}
                            {(userData.tier === 1 && userData.category === 'Hombre') && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>VERIFICADO</h2><img className='categoryImg' src={verified} alt='verificadas'></img></div>}
                            <div className='postDataTitle'>
                                <h2>{userData.name?.toUpperCase()}</h2>
                                <span onClick={copiarAlPortapapeles}><ContentCopyIcon /></span>
                            </div>
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <Button variant='contained'><Anchor to='/editar-perfil' style={{ textDecoration: 'none', color: 'white' }}>Editar</Anchor></Button>
                                <Button variant='contained' sx={{ backgroundColor: '#c90035' }}><Anchor style={{ textDecoration: 'none', color: 'white' }} onClick={handleDelete}>Eliminar Anuncio</Anchor></Button>
                            </div>
                            <div div style={{ display: 'flex', gap: '5px' }}>
                                <Button variant='contained'><Anchor to={`/anuncio/${userData.nameURL}`} style={{ textDecoration: 'none', color: 'white' }}>Ver Como Cliente</Anchor></Button>
                                <Button variant='contained'><Anchor to='/comprar-monedas' style={{ textDecoration: 'none', color: 'white' }}>Mejorar Anuncio</Anchor></Button>
                            </div>
                            <div style={{ display: 'flex', gap: '5px' }} className='userBtns'>
                                <Anchor to={`https://api.whatsapp.com/send?phone=${userData.phone}&text=Hola ${userData.name} te vi en EncantosArdientes.com y me gustaría...`}><Button variant='contained' sx={{ width: '120px' }}><WhatsAppIcon /></Button></Anchor>
                                <Anchor to={`tel:+${userData.phone}`}><Button variant='contained' sx={{ width: '120px' }}><LocalPhoneIcon /></Button></Anchor>
                            </div>
                            <section style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                <h3 className='userCategory'>{userData.category}</h3>
                                <ol>
                                    <li>
                                        <WhatsAppIcon fontSize='' sx={{ color: 'green' }} />+{userData.phone}
                                    </li>
                                    {
                                        userProperties.length &&
                                        userProperties?.map((property, index) =>
                                            (property[1] !== 0 && property[1] !== '-') && (
                                                <li key={index}>
                                                    <p>{property[1]}</p> <small>{property[0]?.toUpperCase()}</small>
                                                </li>
                                            )
                                        )
                                    }
                                </ol>
                            </section>
                            <section className='aboutMe'>
                                <h3>Sobre Mí</h3>
                                <div className={`description ${expanded ? 'expanded' : ''}`}>
                                    <p>{userData.description}</p>
                                </div>
                                {!expanded ? (
                                    <Anchor className="text-primary" onClick={toggleExpansion}>
                                        Continuar leyendo
                                    </Anchor>
                                ) : (
                                    <Anchor className="text-primary" onClick={toggleExpansion}>
                                        Leer Menos
                                    </Anchor>
                                )
                                }
                            </section>
                        </div>
                        <div className='profileImageContainer'>
                            {
                                userData.video ?
                                    <video autoPlay loop muted src={userData.video} alt={userData.name}></video>
                                    :
                                    <img src={userData.image} alt={userData.name}></img>
                            }
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '3vw' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='userDataAndBtns'>
                                <div className='editAndDeleteBtns' style={{ display: 'flex', gap: '5px' }}>
                                    <Button variant='contained' className='editBtn'><Anchor to='/editar-perfil' style={{ textDecoration: 'none', color: 'white' }}>Editar</Anchor></Button>
                                    <Button variant='contained' className='editBtn'><Anchor to={`/anuncio/${userData.nameURL}`} style={{ textDecoration: 'none', color: 'white' }}>Ver Como Cliente</Anchor></Button>
                                    <Button variant='contained' className='editBtn'><Anchor to='/comprar-monedas' style={{ textDecoration: 'none', color: 'white' }}>Mejorar Anuncio</Anchor></Button>
                                    <Button variant='contained' sx={{ backgroundColor: '#c90035' }} className='deleteBtn'><Anchor style={{ textDecoration: 'none', color: 'white' }} onClick={handleDelete}>Eliminar Anuncio</Anchor></Button>
                                </div>

                                <div style={{ display: 'flex', gap: '5px' }} className='userBtns'>
                                    <Anchor to={`https://api.whatsapp.com/send?phone=${userData.phone}&text=Hola ${userData.name} te vi en EncantosArdientes.com y me gustaría...`}><Button variant='contained' sx={{ width: '120px' }}><WhatsAppIcon /></Button></Anchor>
                                    <Anchor to={`tel:+${userData.phone}`}><Button variant='contained' sx={{ width: '120px' }}><LocalPhoneIcon /></Button></Anchor>
                                </div>

                            </div>
                            <div className='profileImageContainer'>
                                {
                                    userData.video ?
                                        <video autoPlay loop muted src={userData.video} alt={userData.name}></video>
                                        :
                                        <img src={userData.image} alt={userData.name}></img>
                                }
                            </div>
                        </div>
                        <div className='postDataTitle'>
                            <h2>{userData.name?.toUpperCase()}</h2>
                            <span onClick={copiarAlPortapapeles}><ContentCopyIcon /></span>
                        </div>
                        <section style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                            <h3 className='userCategory'>{userData.category}</h3>
                            <ol>
                                <li>
                                    <WhatsAppIcon fontSize='' sx={{ color: 'green' }} />+{userData.phone}
                                </li>
                                {
                                    userProperties.length &&
                                    userProperties?.map((property, index) =>
                                        (property[1] !== 0 && property[1] !== '-') && (
                                            <li key={index}>
                                                <p>{property[1]}</p> <small>{property[0]?.toUpperCase()}</small>
                                            </li>
                                        )
                                    )
                                }
                            </ol>
                        </section>
                        {/* IMAGENES */}
                        <section className='aboutMe'>
                            <h3>Sobre Mí</h3>
                            <div className={`description ${expanded ? 'expanded' : ''}`}>
                                <p>{userData.description}</p>
                            </div>
                            {!expanded ? (
                                <Anchor className="text-primary" onClick={toggleExpansion}>
                                    Continuar leyendo
                                </Anchor>
                            ) : (
                                <Anchor className="text-primary" onClick={toggleExpansion}>
                                    Leer Menos
                                </Anchor>
                            )
                            }
                        </section>
                    </div>
            }
            <div style={{ display: 'flex', gap: '5px' }}>
                <Button variant='container' className='uploadBtn' onClick={handleOpenImage}>Subí una foto</Button>
                <Button variant='container' className='uploadBtn' onClick={handleOpenVideo}>Subí un video</Button>
            </div>
            {
                (videos.length || images.length) ? <div><Button variant={wantDelete ? 'contained' : 'outlined'} onClick={() => { setWantDelete(!wantDelete) }}>Editar Galeria</Button></div> : <></>
            }
            <Modal
                open={openImage}
                onClose={handleCloseImage}
            >
                <Box className='uploadModal'>
                    <Typography id="modal-modal-title" variant="h6">
                        Subir Fotos
                    </Typography>
                    <input ref={imageInput} multiple className='inputImage' required accept="image/*" id="image" name='image' type="file" />
                    <Button variant='container' className='uploadBtn' onClick={uploadImage}>Enviar</Button>
                    <p>Toca afuera para cerrar</p>
                </Box>
            </Modal>
            <Modal
                open={openVideo}
                onClose={handleCloseVideo}
            >
                <Box className='uploadModal'>
                    <Typography id="modal-modal-title" variant="h6">
                        Subí Un Video
                    </Typography>
                    <input ref={videoInput} multiple className='inputImage' required accept="video/*" id="video" name='video' type="file" />
                    <Button variant='container' className='uploadBtn' onClick={uploadVideo}>Enviar</Button>
                    <p>Toca afuera para cerrar</p>
                </Box>
            </Modal>
            <ProfileImages userData={userData} wantDelete={wantDelete} setReload={setReload} reload={reload} images={images} videos={videos} />
            <div className='commentBoxContainer'>
                {comments.length ? <h3>- Comentarios -</h3> : <></>}
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {
                        comments.length ?
                            comments.length ?
                                comments.map((comment, i) => {

                                    return <section className='commentBox' key={i}>
                                        {
                                            comment.user_id._id === user.id ?
                                                <div className='commentHeader'>
                                                    <h3>{comment.user_id.name}</h3>
                                                    <div>
                                                        <Button id={comment._id} className='editComment' variant='contained' onClick={handleEditComment}><EditIcon id={comment._id} /></Button>
                                                        <Button id={comment._id} className='deleteComment' variant='contained' onClick={handleDeleteComment}><DeleteIcon id={comment._id} /></Button>
                                                    </div>
                                                </div>
                                                : <h3>{comment.user_id.name}</h3>
                                        }
                                        {
                                            comment.user_id._id === user.id ?
                                                <Textarea id={`textArea${comment._id}`} aria-label="minimum height" minRows={1} defaultValue={comment.text} />
                                                : <Textarea disabled aria-label="minimum height" minRows={1} defaultValue={comment.text} />
                                        }
                                    </section>
                                })
                                : <></>
                            : <></>
                    }
                </div>
            </div>
        </main >
    )
}
