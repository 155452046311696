import React from 'react'
import './paymentDisplay.css'
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import StarIcon from '@mui/icons-material/StarBorder';
import Typography from '@mui/material/Typography';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useRef } from 'react';
import { LoadStart, LoadRemove } from '../../components/Loading'
import { Link as Anchor } from 'react-router-dom';

const tiers = [
    {
        title: 'Verificado',
        subheader: 'Más popular',
        price: '10.000',
        description: [
            'Aparece en la sección VERIFICADAS/OS',
        ],
        buttonText: 'OBTENER',
        id: 1,
        buttonVariant: 'contained',
    },
    {
        title: 'Oro',
        price: '25.000',
        description: [
            'Aparecé en la sección ORO'
        ],
        buttonText: 'OBTENER',
        id: 2,
        buttonVariant: 'contained',
    },
    {
        title: 'Platino',
        price: '35.000',
        description: [
            'Aparece en la sección PLATINO'
        ],
        buttonText: 'OBTENER',
        id: 3,
        buttonVariant: 'contained',
    },
    {
        title: 'Diamante',
        price: '45.000',
        description: [
            'Aparece en la sección DIAMANTE'
        ],
        buttonText: 'OBTENER',
        id: 4,
        buttonVariant: 'contained',
    },
    {
        title: 'Diamante VIP',
        price: '60.000',
        description: [
            'Aparece en la sección DIAMANTE VIP'
        ],
        buttonText: 'OBTENER',
        id: 5,
        buttonVariant: 'contained',
    },
];

const defaultTheme = createTheme();

export default function PaymentDisplay() {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const headers = { headers: { 'Authorization': `Bearer ${token}` } }
    let params = new URLSearchParams(window.location.search);
    let payment_id = params.get("payment_id");
    let status = params.get("status");
    let value = params.get("value")
    let coins = Number(params.get("coins"))
    let user = JSON.parse(localStorage.getItem("user"));
    const coinsInput = useRef()

    useEffect(() => {
        if (status === "approved") {
            handlePayment()
        }
    }, [])

    async function handlePayment() {
        LoadStart()
        try {
            let url = `https://encantos-ardientes.onrender.com/payment/create`;
            let data = {
                payment_id,
                value,
                coins,
            };
            const res = await axios.post(url, data, headers)
            toast.success(`Gracias por comprar con nosotros ${user.name}`)
            localStorage.setItem(
                "user",
                JSON.stringify({
                    id: res.data.user._id,
                    name: res.data.user.name,
                    email: res.data.user.email,
                    photo: res.data.user.photo,
                    coins: res.data.user.coins,
                    admin: res.data.user.is_admin,
                    has_posted: res.data.user.has_posted,
                    loginAt: res.data.user.loginAt
                })
            );
            LoadRemove()
            setTimeout(() => {
                navigate('/')
            }, 1000)
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function proccessPayment(e) {
        try {
            e.preventDefault();
            let url = 'https://encantos-ardientes.onrender.com/payment/process'
            let quantityInput = Number(coinsInput.current.value)
            const data = {
                quantity: quantityInput
            }
            const res = await axios.post(url, data, headers)
            if (res) {
                const url = res.data.response.init_point
                const win = window.open(url);
                win.focus();
            }
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleUpgrade(e) {
        LoadStart()
        try {
            const url = 'https://encantos-ardientes.onrender.com/post/upgrade'
            const tier = Number(e.target.id)
            let coins = 0
            switch (tier) {
                case 1:
                    coins = 10000
                    break;
                case 2:
                    coins = 25000
                    break;
                case 3:
                    coins = 35000
                    break;
                case 4:
                    coins = 45000
                    break;
                case 5:
                    coins = 60000
                    break;
                default:
                    return
            }
            const data = {
                tier,
                coins
            }
            const res = await axios.put(url, data, headers)
            toast.success(res.data.message)
            const postStorage = res.data.post
            localStorage.setItem('post', JSON.stringify({
                ...postStorage
            }))
            localStorage.setItem(
                "user",
                JSON.stringify({
                    id: res.data.updUser._id,
                    name: res.data.updUser.name,
                    email: res.data.updUser.email,
                    photo: res.data.updUser.photo,
                    coins: res.data.updUser.coins,
                    admin: res.data.updUser.is_admin,
                    has_posted: res.data.updUser.has_posted,
                    loginAt: res.data.updUser.loginAt
                })
            );
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    return (
        <ThemeProvider theme={defaultTheme}>
            <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
            <CssBaseline />
            {/* Hero unit */}
            <main className='paymentContainer'>
                <Container disableGutters maxWidth="md" sx={{ pt: 8, pb: 6, pl: 1, pr: 1 }}>
                    <Typography
                        component="h2"
                        variant="h2"
                        align="center"
                        color="text.primary"
                        gutterBottom
                    >
                        Compra Monedas
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" component="p" fontSize={'15px'} sx={{ 'line-height': '1.4' }}>
                        ¡Descubre una forma única de expresar afecto y destacarte!<br />
                        Nuestras monedas te ofrecen la oportunidad de sorprender a tus chicas favoritas con regalos exclusivos.<br />
                        ¿Eres una chica? Canjea tus monedas para elevar tu estatus y recibir mas visibilidad.
                        Con cada moneda, estás creando momentos especiales y construyendo conexiones significativas.<br />
                        Adquiere tus monedas ahora y dale un toque especial a tu experiencia en nuestra plataforma.<br />
                        ¡Regalos, estatus y momentos inolvidables te esperan!
                    </Typography>
                </Container>
                {
                    token ? <Container disableGutters maxWidth="md" sx={{ pb: 6, pl: 1, pr: 1 }}>
                        <Typography
                            component="p"
                            variant="h7"
                            align="center"
                            color="text.primary"
                            gutterBottom
                        >
                            1 MONEDA = AR$1
                        </Typography>
                        <div className='monedasInput'>
                            <input ref={coinsInput} type='number' name='coins' id='coinsInput' defaultValue={0}></input>
                            <Button variant='contained' onClick={proccessPayment}>COMPRAR</Button>
                        </div>
                    </Container>
                        :
                        <Typography component="p"
                            variant="h7"
                            align="center"
                            color="text.primary"
                            gutterBottom
                            mb={1}
                            >
                            Para conseguír monedas primero <Anchor to='/auth'>Inicia Sesión</Anchor>
                        </Typography>
                }
                {/* End hero unit */}
                {
                    user.has_posted ?
                        <>
                            <Typography
                                component="h2"
                                variant="h2"
                                align="center"
                                color="text.primary"
                                gutterBottom
                            >
                                Mejora tu Anuncio
                            </Typography>
                            <Container maxWidth="md" sx={{ pb: 6 }}>
                                <Grid container spacing={5} alignItems="center">
                                    {tiers.map((tier) => (
                                        // Enterprise card is full width at sm breakpoint
                                        <Grid
                                            item
                                            key={tier.title}
                                            xs={12}
                                            sm={6}
                                            md={4}
                                        >
                                            <Card sx={{ height: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                                                <CardHeader
                                                    title={tier.title}
                                                    subheader={tier.subheader}
                                                    titleTypographyProps={{ align: 'center' }}
                                                    action={tier.title === 'Verificado' ? <StarIcon /> : null}
                                                    subheaderTypographyProps={{
                                                        align: 'center',
                                                    }}
                                                    sx={{
                                                        backgroundColor: (theme) =>
                                                            theme.palette.mode === 'light'
                                                                ? theme.palette.grey[200]
                                                                : theme.palette.grey[700],
                                                    }}
                                                />
                                                <CardContent>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'baseline',
                                                            mb: 2,
                                                        }}
                                                    >
                                                        <Typography component="h2" variant="h3" color="text.primary">
                                                            {tier.price}
                                                        </Typography>
                                                        <Typography variant="h6" color="text.secondary">
                                                            /mes
                                                        </Typography>
                                                    </Box>
                                                    <ul>
                                                        {tier.description.map((line) => (
                                                            <Typography
                                                                component="li"
                                                                variant="subtitle1"
                                                                align="center"
                                                                key={line}
                                                            >
                                                                {line}
                                                            </Typography>
                                                        ))}
                                                    </ul>
                                                </CardContent>
                                                <CardActions>
                                                    <Button onClick={handleUpgrade} id={tier.id} fullWidth variant={tier.buttonVariant}>
                                                        {tier.buttonText}
                                                    </Button>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Container>
                        </>
                        : <></>
                }
            </main>
        </ThemeProvider>
    )
}
