import IndexLayout from '../layouts/IndexLayout'
import AuthLayout from '../layouts/AuthLayout'
import TokenLayout from '../layouts/TokenLayout'
import LegalLayout from '../layouts/LegalLayout'
import Home from "./Home/Home";
import Auth from './Auth/Auth';
import NotFoundPage from './NotFoundPage/NotFoundPage';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import PrivacyPolity from './PrivacyPolity/PrivacyPolity'
import Contact from './Contact/Contact'
import Profile from './Profile/Profile';
import EditProfile from './EditProfile/EditProfile';
import TransDisplay from './TransDisplay/TransDisplay';
import MujerDisplay from './MujerDisplay/MujerDisplay';
import HombreDisplay from './HombreDisplay/HombreDisplay';
import PostDisplay from './PostDisplay/PostDisplay';
import PaymentDisplay from './PaymentDisplay/PaymentDisplay';
import Admin from './Admin/Admin'

import { createBrowserRouter } from "react-router-dom";

export const router = createBrowserRouter([
    {
        path: '/',
        element: <IndexLayout />,
        children: [
            { path: '/', element: <Home /> },
            { path: '/trans/', element: <TransDisplay /> },
            { path: '/trans/:pais', element: <TransDisplay /> },
            { path: '/trans/:pais/:provincia', element: <TransDisplay /> },
            { path: '/trans/:pais/:provincia/:localidad', element: <TransDisplay /> },
            { path: '/trans/:pais/:provincia/:localidad/:barrio', element: <TransDisplay /> },
            { path: '/mujeres/', element: <MujerDisplay /> },
            { path: '/mujeres/:pais', element: <MujerDisplay /> },
            { path: '/mujeres/:pais/:provincia', element: <MujerDisplay /> },
            { path: '/mujeres/:pais/:provincia/:localidad', element: <MujerDisplay /> },
            { path: '/mujeres/:pais/:provincia/:localidad/:barrio', element: <MujerDisplay /> },
            { path: '/hombres/', element: <HombreDisplay /> },
            { path: '/hombres/:pais', element: <HombreDisplay /> },
            { path: '/hombres/:pais/:provincia', element: <HombreDisplay /> },
            { path: '/hombres/:pais/:provincia/:localidad', element: <HombreDisplay /> },
            { path: '/hombres/:pais/:provincia/:localidad/:barrio', element: <HombreDisplay /> },
            { path: '/anuncio/:nameURL', element: <PostDisplay /> },
            { path: '/comprar-monedas', element: <PaymentDisplay /> },
        ]
    },
    {
        path: '/',
        element: <TokenLayout />,
        children: [
            { path: '/perfil', element: <Profile /> },
            { path: '/editar-perfil', element: <EditProfile /> },
            { path: '/admin', element: <Admin /> },
        ]
    },
    {
        path: '/',
        element: <AuthLayout />,
        children: [
            { path: '/auth', element: <Auth /> },
            { path: '/recuperar/:userId', element: <ForgotPassword /> },
        ]
    },
    {
        path: '/',
        element: <LegalLayout />,
        children: [
            { path: '/terminos-y-condiciones', element: <TermsAndConditions /> },
            { path: '/politica-de-privacidad', element: <PrivacyPolity /> },
            { path: '/contactanos', element: <Contact /> },
        ]
    },
    {
        path: '*',
        element: <IndexLayout />,
        children: [
            { path: '*', element: <NotFoundPage /> },
        ]
    }
])