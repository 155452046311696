import React, { useEffect, useState } from 'react'
import './postDisplay.css'
import { Link as Anchor, useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from 'axios';
import toast from 'react-hot-toast';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Button } from '@mui/material';
import ProfileImages from '../../components/ProfileImages/ProfileImages';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from '@mui/material';
import { Box } from '@mui/material';
import { Typography } from '@mui/material';
import diamondVip from '../../images/diamante-vip.webp'
import diamond from '../../images/diamante.webp'
import platinum from '../../images/platino.webp'
import gold from '../../images/oro.webp'
import verified from '../../images/verificado.webp'
import { LoadStart, LoadRemove } from '../../components/Loading'

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: grey[300];
    background: grey[900];
    border: 1px solid grey[700];
    box-shadow: 0px 2px 2px 'dark';

    &:hover {
      border-color: blue[400];
    }

    &:focus {
      border-color: blue[400];
      box-shadow: 0 0 0 3px 'dark';
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);

export default function PostDisplay() {
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [segmentos, setSegmentos] = useState([])
    const [userProperties, setUserProperties] = useState({})
    const [expanded, setExpanded] = useState(false);
    const nameURL = window.location.pathname.split("/").pop();
    const [images, setImages] = useState([])
    const [videos, setVideos] = useState([])
    const [comments, setComments] = useState([])
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    let headers = { headers: { 'Authorization': `Bearer ${token}` } }
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const handleOpenTransferModal = () => setOpenTransferModal(true)
    const handleCloseTransferModal = () => setOpenTransferModal(false)
    const [openCommentModal, setOpenCommentModal] = useState(false);
    const handleOpenCommentModal = () => setOpenCommentModal(true)
    const handleCloseCommentModal = () => setOpenCommentModal(false)

    const toggleExpansion = () => {
        setExpanded(!expanded);
    };

    function getCity(segmentosUrl) {
        var profileUrl = segmentosUrl.split('/').map(function (segmento) {
            var palabras = segmento.split('-');
            palabras = palabras.map(function (palabra) {
                return palabra.charAt(0).toUpperCase() + palabra.slice(1);
            });
            return palabras.join(' ');
        });
        setSegmentos(profileUrl)
    }

    function getProperties(user) {
        const CABELLO = user.hair_color
        const OJOS = user.eyes_color
        const DEPILACIÓN = user.depilation
        const CUERPO = user.body_type
        const BUSTO = user.tits_type
        const COLA = user.ass_type
        const HORARIO = user.work_hours

        const properties = {
            años: user.age,
            altura: user.height,
            cm: user.dotation,
            CABELLO,
            OJOS,
            DEPILACIÓN,
            CUERPO,
            BUSTO,
            COLA,
            HORARIO
        }
        const propertiesList = Object.entries(properties)
        setUserProperties(propertiesList)
    }

    async function getData() {
        try {
            let url = `https://encantos-ardientes.onrender.com/post/${nameURL}`
            const res = await axios.get(url)
            localStorage.setItem('post', JSON.stringify({
                ...res.data.post
            }))
            setUserData(res.data.post)
            getCity(res.data.post.city)
            getProperties(res.data.post)
            setImages(res.data.postImages)
            setVideos(res.data.postVideos)
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    const copiarAlPortapapeles = () => {
        // Crear un elemento de textarea temporal
        const textarea = document.createElement('textarea');
        // Asignar el texto a copiar al contenido del textarea
        textarea.value = `http://localhost:3000/anuncio/${userData.nameURL}`;
        // Asegurarse de que el textarea no sea visible
        textarea.style.position = 'fixed';
        textarea.style.opacity = 0;
        // Agregar el textarea al DOM
        document.body.appendChild(textarea);
        // Seleccionar el contenido del textarea
        textarea.select();

        try {
            // Intentar copiar el contenido al portapapeles
            document.execCommand('copy');
            toast.success('Link copiado al portapapeles');
        } catch (err) {
            toast.error('Error al intentar copiar al portapapeles:', err);
        } finally {
            // Eliminar el textarea temporal
            document.body.removeChild(textarea);
        }
    };

    function goToZone(e) {
        if (userData.city) {
            let string1 = userData.city;
            let string2 = e.target.text.toLowerCase().replace(' ', '-');
            var partes = string1.split("/");
            var index = partes.indexOf(string2);
            var resultado = partes.slice(0, index + 1).join("/");
            let userCategory = userData.category.toLowerCase()
            if (userCategory === 'hombre') {
                userCategory = 'hombres'
            }
            if (userCategory === 'mujer') {
                userCategory = 'mujeres'
            }
            if (string2 === 'argentina') {
                navigate(`/${userCategory}`)
            } else {
                navigate(`/${userCategory}/${resultado}`)
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        if (userData._id) {
            getComments()
        }
    }, [userData])

    async function getComments() {
        try {
            let url = `https://encantos-ardientes.onrender.com/comment/${userData._id}`
            const res = await axios.get(url)
            setComments(res.data.comments)
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    async function handleCreateComment() {
        LoadStart()
        try {
            const url = `https://encantos-ardientes.onrender.com/comment/create`
            const text = document.getElementById('commentText').value
            const data = {
                post_id: userData._id,
                text
            }
            const res = await axios.post(url, data, headers)
            toast.success(res.data.message)
            handleCloseCommentModal()
            LoadRemove()
            getData()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Debes iniciar sesión')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleEditComment(e) {
        LoadStart()
        const element = e.target.localName
        let id = e.target.id
        if (element === 'path') {
            id = e.target.parentElement.id
        }
        try {
            const url = `https://encantos-ardientes.onrender.com/comment/edit/${id}`
            const textArea = document.getElementById(`textArea${id}`)
            const text = textArea.value
            const data = {
                text
            }
            const res = await axios.put(url, data, headers)
            toast.success(res.data.message)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleDeleteComment(e) {
        LoadStart()
        const element = e.target.localName
        let id = e.target.id
        if (element === 'path') {
            id = e.target.parentElement.id
        }
        try {
            const url = `https://encantos-ardientes.onrender.com/comment/delete/${id}`
            const res = await axios.delete(url, headers)
            toast.success(res.data.message)
            getComments()
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function handleReport() {
        LoadStart()
        try {
            const url = 'https://encantos-ardientes.onrender.com/report/create'
            const text = document.getElementById('reportText').value
            const data = {
                post_id: userData._id,
                text
            }
            const res = await axios.post(url, data, headers)
            toast.success(res.data.message)
            handleCloseModal()
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    async function handleTransfer() {
        LoadStart()
        try {
            const url = `https://encantos-ardientes.onrender.com/transfer/create/${userData._id}`
            const coins = Number(document.getElementById('transferCant').value)
            const data = {
                coins
            }
            const res = await axios.post(url, data, headers)
            toast.success(res.data.message)
            handleCloseTransferModal()
            localStorage.setItem(
                "user",
                JSON.stringify({
                    id: res.data.updatedUser._id,
                    name: res.data.updatedUser.name,
                    email: res.data.updatedUser.email,
                    photo: res.data.updatedUser.photo,
                    coins: res.data.updatedUser.coins,
                    admin: res.data.updatedUser.is_admin,
                    has_posted: res.data.updatedUser.has_posted,
                    loginAt: res.data.updatedUser.loginAt
                })
            );
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    return (
        <main className='postDisplayContainer'>
            <div className='urlCardContainer'>
                {
                    segmentos?.map((urlSection, i) => {
                        return <section className='urlCard' key={i}>
                            <Anchor to='#' className='zonesUrl' onClick={goToZone}>{urlSection}</Anchor>
                            {segmentos.length - 1 > i && <KeyboardArrowRightIcon fontSize='5px' />}
                        </section>
                    })
                }
            </div>
            {
                window.innerWidth >= 767 ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}>
                        <div className='profileDataContainer'>
                            {userData.tier === 5 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE VIP</h2><img className='categoryImg' src={diamondVip} alt='diamante'></img></div>}
                            {userData.tier === 4 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE</h2><img className='categoryImg' src={diamond} alt='diamante'></img></div>}
                            {userData.tier === 3 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>PLATINO</h2><img className='categoryImg' src={platinum} alt='platino'></img></div>}
                            {userData.tier === 2 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>ORO</h2><img className='categoryImg' src={gold} alt='oro'></img></div>}
                            {(userData.tier === 1 && (userData.category === 'Trans' || userData.category === 'Mujer')) && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>VERIFICADA</h2><img className='categoryImg' src={verified} alt='verificadas'></img></div>}
                            {(userData.tier === 1 && userData.category === 'Hombre') && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>VERIFICADO</h2><img className='categoryImg' src={verified} alt='verificadas'></img></div>}
                            <div className='postDataTitle'>
                                <h2>{userData.name?.toUpperCase()}</h2>
                                <span onClick={copiarAlPortapapeles}><ContentCopyIcon /></span>
                            </div>
                            <div style={{ display: 'flex', gap: '5px' }} className='userBtns'>
                                <Anchor to={`https://api.whatsapp.com/send?phone=${userData.phone}&text=Hola ${userData.name} te vi en EncantosArdientes.com y me gustaría...`}><Button variant='contained' sx={{ width: '120px' }}><WhatsAppIcon /></Button></Anchor>
                                <Anchor to={`tel:+${userData.phone}`}><Button variant='contained' sx={{ width: '120px' }}><LocalPhoneIcon /></Button></Anchor>
                            </div>
                            <Anchor onClick={handleOpenTransferModal} style={{width: '180px'}}><Button variant='contained' sx={{ width: '180px' }}>Regalar Monedas</Button></Anchor>
                            <section style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                                <h3 className='userCategory'>{userData.category}</h3>
                                <ol>
                                    <li>
                                        <WhatsAppIcon fontSize='' sx={{ color: 'green' }} />+{userData.phone}
                                    </li>
                                    {
                                        userProperties.length &&
                                        userProperties?.map((property, index) =>
                                            (property[1] !== 0 && property[1] !== '-') && (
                                                <li key={index}>
                                                    <small style={{marginTop: 0}}>{property[0]?.toUpperCase()}:</small><p>{property[1]}</p>
                                                </li>
                                            )
                                        )
                                    }
                                </ol>
                            </section>
                            <section className='aboutMe'>
                                <h3>Sobre Mí</h3>
                                <div className={`description ${expanded ? 'expanded' : ''}`}>
                                    <p>{userData.description}</p>
                                </div>
                                {!expanded ? (
                                    <Anchor className="text-primary" onClick={toggleExpansion}>
                                        Continuar leyendo
                                    </Anchor>
                                ) : (
                                    <Anchor className="text-primary" onClick={toggleExpansion}>
                                        Leer Menos
                                    </Anchor>
                                )
                                }
                            </section>
                        </div>
                        <div className='profileImageContainer'>
                            {
                                userData.video ?
                                    <video autoPlay loop muted src={userData.video} alt={userData.name}></video>
                                    :
                                    <img src={userData.image} alt={userData.name}></img>
                            }
                        </div>
                    </div>
                    :
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '3vw' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className='userDataAndBtns'>
                                <div className='postDataTitle'>
                                    <h2>{userData.name?.toUpperCase()}</h2>
                                    <span onClick={copiarAlPortapapeles}><ContentCopyIcon /></span>
                                </div>
                                <div style={{ display: 'flex', gap: '5px' }} className='userBtns'>
                                    <Anchor to={`https://api.whatsapp.com/send?phone=${userData.phone}&text=Hola ${userData.name} te vi en EncantosArdientes.com y me gustaría...`}><Button variant='contained' sx={{ width: '120px' }}><WhatsAppIcon /></Button></Anchor>
                                    <Anchor to={`tel:+${userData.phone}`}><Button variant='contained' sx={{ width: '120px' }}><LocalPhoneIcon /></Button></Anchor>
                                </div>
                                <Anchor onClick={handleOpenTransferModal} style={{width: '180px'}}><Button variant='contained' sx={{ width: '149px', fontSize: '10px' }}>Regalar Monedas</Button></Anchor>
                            </div>
                            <div className='profileImageContainer'>
                                {
                                    userData.video ?
                                        <video autoPlay loop muted src={userData.video} alt={userData.name}></video>
                                        :
                                        <img src={userData.image} alt={userData.name}></img>
                                }
                            </div>
                        </div>
                        <section style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                            <h3 className='userCategory'>{userData.category}</h3>
                            <ol>
                                <li>
                                    <WhatsAppIcon fontSize='' sx={{ color: 'green' }} />+{userData.phone}
                                </li>
                                {
                                    userProperties.length &&
                                    userProperties?.map((property, index) =>
                                        (property[1] !== 0 && property[1] !== '-') && (
                                            <li key={index}>
                                            <small style={{marginTop: 0}}>{property[0]?.toUpperCase()}:</small><p>{property[1]}</p>
                                            </li>
                                        )
                                    )
                                }
                            </ol>
                        </section>
                        <section className='aboutMe'>
                            <h3>Sobre Mí</h3>
                            <div className={`description ${expanded ? 'expanded' : ''}`}>
                                <p>{userData.description}</p>
                            </div>
                            {!expanded ? (
                                <Anchor className="text-primary" onClick={toggleExpansion}>
                                    Continuar leyendo
                                </Anchor>
                            ) : (
                                <Anchor className="text-primary" onClick={toggleExpansion}>
                                    Leer Menos
                                </Anchor>
                            )
                            }
                        </section>
                    </div>
            }
            <div className='reportBtn'><Button variant='outlined' onClick={handleOpenModal}>Reportar</Button></div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                <Box className='uploadModal reportModal'>
                    <Typography id="modal-modal-title" variant="h6">
                        Reportar
                    </Typography>
                    <Textarea id='reportText' aria-label="minimum height" minRows={1} placeholder='Motivo' />
                    <Button variant='container' className='uploadBtn' onClick={handleReport}>Enviar</Button>
                    <p>Toca afuera para cerrar</p>
                </Box>
            </Modal>
            <ProfileImages userData={userData} images={images} videos={videos} />
            <div className='commentBoxContainer'>
                <h3>- Comentarios -</h3>
                <div><Button variant='outlined' onClick={handleOpenCommentModal}>COMENTAR</Button></div>
                <p style={{ fontSize: '12px' }}>Para editar tu comentario, cambia lo que escribiste y dale click al lapiz azul.</p>
                {
                    comments.length ? <></> : <h3>Todavía no hay comentarios</h3>
                }
                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    {
                        comments.length ?
                            comments.length ?
                                comments.map((comment, i) => {

                                    return <section className='commentBox' key={i}>
                                        {
                                            comment.user_id._id === user.id ?
                                                <div className='commentHeader'>
                                                    <h3>{comment.user_id.name}</h3>
                                                    <div>
                                                        <Button id={comment._id} className='editComment' variant='contained' onClick={handleEditComment}><EditIcon id={comment._id} /></Button>
                                                        <Button id={comment._id} className='deleteComment' variant='contained' onClick={handleDeleteComment}><DeleteIcon id={comment._id} /></Button>
                                                    </div>
                                                </div>
                                                : <h3>{comment.user_id.name}</h3>
                                        }
                                        {
                                            comment.user_id._id === user.id ?
                                                <Textarea id={`textArea${comment._id}`} aria-label="minimum height" minRows={1} defaultValue={comment.text} />
                                                : <Textarea disabled aria-label="minimum height" minRows={1} defaultValue={comment.text} />
                                        }
                                    </section>
                                })
                                : <></>
                            : <></>
                    }
                </div>
            </div>
            {/* modal transfer */}
            <Modal
                open={openTransferModal}
                onClose={handleCloseTransferModal}
            >
                <Box className='uploadModal reportModal'>
                    <Typography id="modal-modal-title" variant="h6">
                        Regalar Monedas
                    </Typography>
                    <Button fullWidth className='navItem' variant='outlined' sx={{ width: '88%' }}>
                        Tenes {user.coins} Monedas
                    </Button>
                    <Textarea id='transferCant' aria-label="minimum height" minRows={1} placeholder='Cantidad' />
                    <Button variant='container' className='uploadBtn' onClick={handleTransfer}>Enviar</Button>
                    <p>Toca afuera para cerrar</p>
                </Box>
            </Modal>
            <Modal
                open={openCommentModal}
                onClose={handleCloseCommentModal}
            >
                <Box className='uploadModal reportModal'>
                    <Typography id="modal-modal-title" variant="h6">
                        Comentar
                    </Typography>
                    <Typography id="modal-modal-title" variant="p">
                        Recordá tener la sesión iniciada
                    </Typography>
                    <Textarea id='commentText' aria-label="minimum height" minRows={1} placeholder='Comentario' />
                    <Button variant='container' className='uploadBtn' onClick={handleCreateComment}>Enviar</Button>
                    <p>Toca afuera para cerrar</p>
                </Box>
            </Modal>
        </main >
    )
}
