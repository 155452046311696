import * as React from 'react';
import './termsModal.css'
import { useEffect } from 'react';
import { Link as Anchor } from 'react-router-dom';
import { Button } from '@mui/material';
import adultsWarning from '../../images/18.webp'

export default function TermsModal() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);

    function handleClose() {
        const data = {
            accepted: true
        }
        sessionStorage.setItem('terms', JSON.stringify(data))
        setOpen(false)
    }

    useEffect(() => {
        const terms = JSON.parse(sessionStorage.getItem('terms')) || ''
        if (!terms.accepted) {
            handleOpen()
        }
    }, [])

    return (
        <>
            {
                open ? <div className='termsModal'>
                    <section className='modal'>
                        <img src={adultsWarning} alt='+18'></img>
                        <h2>Por favor lee esta advertencia antes de continuar</h2>
                        <p><strong>Soy mayor de 18 años</strong> y acepto el visionado de textos e imágenes explícitos destinados a un <strong>público adulto</strong>.</p>
                        <p>He leído y acepto los <Anchor to='/terminos-y-condiciones'>Términos y Condiciones</Anchor></p>
                        <Button variant='contained' onClick={handleClose}>ACEPTAR</Button>
                    </section>
                </div> : <></>
            }
        </>
    );
}