import React, { useEffect } from 'react'
import './home.css'
import { Grid } from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import escortMujer from '../../images/mujerLogo.webp'
import escortTrans from '../../images/transLogo.webp'
import escortHombre from '../../images/hombreLogo.webp'
import { Link as Anchor } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

export default function Home() {
  const token = localStorage.getItem('token')
  const headers = { headers: { 'Authorization': `Bearer ${token}` } }
  const user = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (user) {
      const expirationTimestamp = new Date(user.loginAt);
      expirationTimestamp.setDate(expirationTimestamp.getDate() + 29); // Sumar 29 días a la fecha del último inicio de sesión

      const currentTimestamp = new Date();
      const timeDifferenceInMilliseconds = expirationTimestamp - currentTimestamp;

      const daysRemaining = Math.ceil(timeDifferenceInMilliseconds / (1000 * 60 * 60 * 24));
      if (daysRemaining > 1 && daysRemaining < 5) {
        renovateToken()
      }
      if (daysRemaining <= 1) {
        localStorage.setItem('token', '')
        localStorage.setItem('user', JSON.stringify({
          id: '',
          admin: '',
          name: '',
          photo: '',
          has_posted: '',
          email: '',
          coins: '',
          loginAt: ''
        }))
        window.location.reload()
      }
    }
  }, [])

  async function renovateToken() {
    try {
      const url = `https://encantos-ardientes.onrender.com/auth/signintoken`
      const res = await axios.put(url, headers)
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: res.data.user._id,
          name: res.data.user.name,
          email: res.data.user.email,
          photo: res.data.user.photo,
          coins: res.data.user.coins,
          admin: res.data.user.is_admin,
          has_posted: res.data.user.has_posted,
          loginAt: res.data.user.loginAt
        })
      );
    } catch (error) {
      if (error.message.includes('CORS')) {
        toast.error('Proba con otro archivo')
      } else {
        if (error.code === "ERR_NETWORK") {
          toast.error("Network Error");
        } else {
          if (error.response?.status === 401) {
            toast.error('Algo salio mal')
          } else {
            if (typeof error.response.data.message === "string") {
              toast.error(error.response.data.message);
            } else {
              error.response.data.message.forEach((err) => toast.error(err));
            }
          }
        }
      }
    }
  }

  useEffect(() => {
    if (!token) {
      localStorage.setItem('token', '')
      localStorage.setItem('user', JSON.stringify({
        id: '',
        admin: '',
        name: '',
        photo: '',
        has_posted: '',
        email: '',
        coins: '',
        loginAt: ''
      }))
    }
  }, [])

  const adsInfo = [
    {
      image: escortMujer,
      title: 'Escorts Mujer',
      description: 'Encuentra en Argentina a las mejores escorts que te ofrecen gran variedad de servicios eróticos. Anímate y no esperes más!',
      to: '/mujeres',
      backgroundPosition: 'center'
    },
    {
      image: escortTrans,
      title: 'Escorts Trans',
      description: 'Atrévete a probar a las más sexys trans y travestis de Argentina. No pierdas la oportunidad de conocerlas, son chicas dispuestas a todo',
      to: '/trans',
      backgroundPosition: 'top'
    },
    {
      image: escortHombre,
      title: 'Escorts Hombre',
      description: 'Encuentra acompañantes masculinos en Argentina. Disfruta de encuentros con hombres. Anímate, haz contacto y no esperes más!',
      to: '/hombres',
      backgroundPosition: 'top'
    }
  ]

  return (
    <main className='home'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {adsInfo.map((ad, i) => (
              <Grid key={i} item>
                <Card sx={{ maxWidth: 300 }}>
                  {
                    ad.backgroundPosition === 'top' ?
                      <CardMedia
                        sx={{ 'height': 280, 'backgroundPosition': 'top' }}
                        image={ad.image}
                        title={ad.title}
                      />
                      : <></>
                  }
                  {
                    ad.backgroundPosition === 'center' ?
                      <CardMedia
                        sx={{ 'height': 280, 'backgroundPosition': 'center' }}
                        image={ad.image}
                        title={ad.title}
                      />
                      : <></>
                  }
                  <CardContent component='section'>
                    <Typography gutterBottom variant="h5" component="h2">
                      {ad.title}
                    </Typography>
                    <Typography variant="body2" color="rgba(0, 0, 0, 0.7)">
                      {ad.description}
                    </Typography>
                  </CardContent>
                  <CardActions sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Anchor to={ad.to}><Button variant='contained' size="small">Ingresar</Button></Anchor>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Typography variant='body2' color="#595959" className='txtSeo'>
        Bienvenido a EncantosArdientes.com, el portal de avisos clasificados eróticos para adultos. Busca y publica tu aviso en cualquiera de las categorías. Anuncios de encuentros y sexo en Argentina. <a title="Escort Buenos Aires" href="https://ar.skokka.com/escorts/buenosaires/">Escorts Buenos Aires</a>,
        escorts, travestis, transexuales, hombre busca hombre, parejas liberales,
        acompañantes, swingers, putas argentinas o encuentra amistades o el amor en nuestra web. ¿Estás solo y quieres pasar buenos momentos en compañía?
        ¿Estás buscando <a title="Escort Mar del Plata" href="https://ar.skokka.com/escorts/mar-del-plata/">Escorts en Mar del Plata</a>,
        hombres o amigos en Argentina? ¡En EncantosArdientes.com están los encuentros eróticos más calientes de la red! Masajes, shemales o simplemente sexo en Argentina.
        Ahora disfruta de las más hermosas escorts de <a title="Escort Tucuman" href="https://ar.skokka.com/escorts/tucuman/">Escorts de Tucumán</a> y <a title="Escort Neuquén" href="https://ar.skokka.com/escorts/neuquen/" style={{ "color": "rgb(149, 152, 156)" }}>Escorts de Neuquén</a>.
        Encuentra lo que estás buscando en EncantosArdientes.com o inserta tu anuncio completamente ¡GRATIS!
      </Typography>
    </main>
  )
}
