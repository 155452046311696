import { configureStore } from "@reduxjs/toolkit";
import authStateReducer from './AuthState/reducer'
import navBarStateReducer from './NavBarState/reducer'

export const store = configureStore({
  reducer: {
    authState: authStateReducer,
    navBarState: navBarStateReducer,
  }
});
