import React from 'react'
import './privacyPolity.css'
import { Link as Anchor } from 'react-router-dom'

export default function PrivacyPolity() {
  return (
    <main className='privacyPolityContainer'>
      <div className="container mt-5"><div>
        <h1 className="main-title page">POLÍTICA DE PRIVACIDAD Y DE PROTECCIÓN DE DATOS
          DE ACUERDO CON EL ARTÍCULO 6º DE LA LEY 25.326 DE PROTECCIÓN DE LOS DATOS PERSONALES</h1>
        <p><strong>1. QUIÉNES SOMOS Y CÓMO PROCESAMOS SUS DATOS PERSONALES</strong></p>
        <p>La persona física Julián Ramos (en adelante, ‘<strong>Julián</strong>’ o el
          'Dueño'), con domicilio social en la C/ Belisario Roldan 2875, B1849, Buenos Aires, Argentina,
          en su calidad de controlador de los datos personales recopilados en el sitio web para adultos EncantosArdientes.com
          (en adelante también denominado 'Sitio web'), presta la máxima
          atención a la seguridad y confidencialidad de los datos personales en el
          desempeño de sus actividades.
        </p>
        <p>El Dueño se reserva el derecho de actualizar periódicamente la política de privacidad actual incluso teniendo en
          cuenta cualquier cambio en la ley aplicable y/o disposiciones de la Agencia de Acceso a la Información Pública
          (AAIP).
        </p>
        <p>Los cambios sustanciales y actualizaciones de la política actual se pondrán en conocimiento de las partes interesadas
          mediante la actualización del enlace de la política de privacidad en el pie de página del Sitio.
        </p>
        <p>Antes de utilizar el Sitio web, lea atentamente esta Política de privacidad. El Dueño recomienda revisar
          regularmente esta política para estar al tanto de la versión más reciente. En caso de cambios significativos, la
          Dueño lo comunicará al usuario mediante correo electrónico.
        </p>
        <p>Esta política de privacidad sigue los criterios establecidos por la ley 25.326 de Protección de los Datos Personales.
        </p>
        <p>Los responsables y encargados del tratamiento de datos de EncantosArdientes.com, así como todas las personas que intervengan en
          cualquier fase de éste, están sujetas al deber de confidencialidad al que se refiere el artículo 10 de la ley
          25.326. Por tanto, su privacidad y sus datos personales están protegidos con los máximos estándares de cumplimiento
          normativo.
        </p>
        <p>En los apartados siguientes se describe cómo son procesados sus datos personales.</p>
        <p><strong>2. QUÉ DATOS PERSONALES SE PUEDEN RECOPILAR</strong></p>
        <p>Las siguientes categorías de datos personales, que usted proporciona por voluntad propia en un acto consentido, libre
          y autorizado, pueden ser recopiladas por El Dueño en el desempeño de sus actividades (el término '<strong>Datos
            personales</strong>' se refiere a todas las categorías enumeradas a
          continuación en su conjunto):
        </p>
        <ul>
          <li><strong>Datos de contacto</strong>: información sobre su nombre, apellidos,
            número de teléfono, dirección de correo
            electrónico;
          </li>
          <li>
            <strong>Datos de pago</strong>: información sobre la compra que ha realizado y el pago (por
            ejemplo, número de tarjeta de crédito/débito);
          </li>
          <li>
            <strong>Otros datos personales</strong>: información sobre su sexo, edad,
            fotografías y otros datos que puede proporcionar espontáneamente mientras
            navega por el Sitio web;
          </li>
          <li>
            <strong>Categorías especiales de datos personales</strong>: información que puede estar
            relacionada con su estilo de vida y comportamiento sexual;
          </li>
          <li>
            <strong>Uso del sitio web</strong>: dirección IP, agente de usuario e
            información adquirida a través de cookies (consulte
            nuestra&nbsp;Política de cookies, en la que se especifican los
            detalles).
          </li>
        </ul>
        <p><strong>3. CÓMO RECOPILAMOS SUS DATOS PERSONALES</strong></p>
        <p>El Dueño recopila y procesa sus Datos personales en los siguientes casos:</p>
        <ul>
          <li>Si se registra en el Sitio web para utilizar todas sus funciones;</li>
          <li>Si publica un anuncio en el Sitio web o responde a un anuncio.</li>
        </ul>
        <p>Si proporciona Datos personales en nombre de otra persona, primero debe asegurarse de que dicha persona ha leído esta <Anchor to="/politica-de-privacidad">Política de Privacidad</Anchor> y da su consentimiento para
          su tratamiento.
        </p>
        <p>El Dueño le recomienda que mantenga sus Datos personales actualizados. Si hay algún cambio, es necesario que nos
          informe al respecto.
        </p>
        <p>Tenga en cuenta que cuando se pone en contacto con cualquier usuario a través del Sitio web utilizando los Datos de
          contacto proporcionados por este último a través de una comunicación autónoma entre usuarios, El Dueño no se hace
          responsable de cómo dicho usuario gestione y procese los Datos personales que usted haya proporcionado. De hecho, el
          usuario contactado actuará como controlador de datos autónomo, no habiendo ninguna actuación por parte de la
          Dueño como intermediario o gestor de los datos, quedando la misma, por tanto, exenta de cualquier responsabilidad
          sobre el uso que, libremente, se hagan de los datos compartidos entre usuarios.
        </p>
        <ul>
          <li><strong>Archivos de Registro</strong></li>
        </ul>
        <p>Cada vez que visita el Sitio, recogemos cierta información sobre el uso de nuestro sitio web para analizar tendencias, administrar el sitio,
          rastrear el movimiento de visitantes en conjunto y recopilar información demográfica para uso global.
          Por tanto, garantizamos que los datos tratados son adecuados, pertinentes y limitados a lo necesario para el fin.
          No vinculamos ninguna de la información de nuestros archivos de registro a ninguna otra información que nos permita determinar su identidad real.
          Esto significa que la sesión de un usuario será rastreada, pero el usuario será anónimo. No compartimos externamente sus archivos de registro.
        </p>
        <ul>
          <li><strong>Cookies</strong></li>
        </ul>
        <p>Utilizamos “cookies” para realizar un seguimiento de cierta información mientras visita nuestro sitio web o utiliza nuestros servicios.
          Las cookies son archivos muy pequeños que se insertan en su computadora y nos permiten contar el número de usuarios de nuestro sitio web y distinguir a los usuarios recurrentes de los usuarios nuevos.
          También nos permiten conservar las preferencias de los usuarios y hacer un seguimiento del uso que realizan para poder continuar desarrollando y mejorando nuestro servicio.
        </p>
        <ul>
          <li><strong>Cookies de Sesión y Permanentes</strong></li>
        </ul>
        <p>Las cookies de sesión son temporales y se eliminarán después de cerrar su navegador.
          Las cookies permanentes se mantienen en su disco duro a menos de que usted las elimine manualmente o hasta que expiren después de un período de tiempo establecido. El propósito principal de las cookies permanentes es hacer que el inicio de sesión en su cuenta sea un proceso más sencillo y para que analicemos su rendimiento o lo realice un socio de confianza. Las cookies pueden ser de sesión o permanentes.
        </p>
        <ul>
          <li><strong>Preferencias&nbsp;de Cookies&nbsp;</strong></li>
        </ul>
        <p>Usted puede elegir aceptar o rechazar las cookies cambiando la configuración de su navegador de Internet. Sin embargo, dependemos de las cookies para el correcto funcionamiento de nuestro sitio web y, si su navegador está configurado para rechazar todas las cookies, el sitio web no funcionará correctamente. Los usuarios que rechacen las cookies asumen toda la responsabilidad por la pérdida de funcionalidad resultante. Para obtener información sobre cómo cambiar la configuración, haga clic en el menú "Ayuda" de la barra de herramientas de su navegador. Para obtener más información sobre la gestión de cookies, visite https://www.allaboutcookies.org/. Tenga en cuenta que las cookies son únicas para cada ordenador. Para poder excluir la recopilación de datos mediante el uso de cookies en cualquiera de sus otros dispositivos, se le pedirá que repita este proceso en cada uno de ellos.
        </p>
        <p><strong>4. PARA QUÉ FINES SE PUEDEN UTILIZAR SUS DATOS PERSONALES</strong></p>
        <p>El Dueño puede procesar sus Datos personales para uno o más de los siguientes fines, de acuerdo con la base legal:
        </p>
        <ul>
          <li>
            <strong>Finalidades estrictamente relacionadas con el acceso al Sitio web y su navegación por este, incluido el
              registro en el Sitio web.</strong> El Dueño puede procesar sus Datos de contacto y otros
            datos personales para permitirle acceder al Sitio web, navegar por él o registrarse en el
            mismo.
            Base legal para el procesamiento: ejecución del contrato entre El Dueño y el usuario.
            Proporcionar sus Datos personales para tal fin es obligatorio; en caso
            de no hacerlo, El Dueño no podrá darle acceso al Sitio web y/o permitirle registrarse.
          </li>
          <li>
            <strong>Publicación de anuncios y posible respuesta a los mismos.</strong> El Dueño puede
            procesar sus Datos de contacto (en concreto, su correo electrónico) para permitirle publicar anuncios o responder a uno.
            Base legal para el procesamiento: la aceptación
            de las condiciones generales de prestación de nuestros servicios, que tiene la fuerza de un
            contrato entre las partes. Por tanto, ejecución de un contrato. Proporcionar sus Datos
            personales para tal fin es obligatorio; en caso de no hacerlo, El Dueño no podrá
            permitirle publicar anuncios ni responder a uno. El Dueño también
            puede procesar con el mismo propósito su fotografía, así
            como Categorías especiales de datos personales.Base legal para el
            procesamiento: su consentimiento expreso e inequívoco, que se le solicitará
            tanto para el posible procesamiento de su fotografía y/o como para el posible
            procesamiento de sus Categorías especiales de datos personales.
            El hecho de no dar su consentimiento no le impedirá publicar o responder a un
            anuncio. Podrá retirar su consentimiento en cualquier momento enviando un correo
            electrónico a la siguiente dirección: <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a>. El retiro del
            consentimiento no afectará al procesamiento realizado antes de dicha retirada.
          </li>
          <li>
            <strong>Procesamiento de pedidos realizados a través del Sitio web y actividades relacionadas con la gestión de
              pedidos. </strong>El Dueño puede procesar sus Datos de contacto y Datos de pago para
            procesar su pedido. Base legal para el procesamiento: ejecución de un
            contrato y cumplimiento de las obligaciones legales relacionadas con dicho contrato. El suministro de dichos
            datos es obligatorio para procesar su pedido; si no lo hace, no podremos procesarlo.
          </li>
          <li>
            <strong>Propósitos de marketing para satisfacer sus necesidades o para informarle de promociones.</strong> El
            Dueño podrá procesar sus Datos personales con fines de marketing y
            publicidad, para informarle de iniciativas de ventas promocionales a través de
            métodos de contacto automatizados (correo electrónico, SMS y otras
            herramientas de comunicación masiva, etc.) y métodos de contacto
            tradicionales (por ejemplo, por teléfono), o para llevar a cabo estudios de mercado y
            estadística, si nos da su consentimiento y dentro de los límites de
            dicha&nbsp;declaración de consentimiento. Base legal para el
            procesamiento: consentimiento. El consentimiento es opcional. La falta de consentimiento no
            tendrá consecuencias contractuales ni efectos en la prestación de
            servicios. Podrá retirar su consentimiento en cualquier momento enviando un correo
            electrónico a la dirección <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a>.
          </li>
          <li>
            <strong>Estudios de mercado y estadística para mejorar el servicio.</strong> El Dueño puede
            procesar sus Datos de contacto para analizar y mejorar sus servicios y ofrecer así una mejor
            satisfacción al cliente. Base legal para el procesamiento:
            interés legítimo de El Dueño en la
            verificación y mejora de sus servicios. Proporcionar sus Datos personales para tal fin es
            obligatorio; en caso de no hacerlo, El Dueño no podrá llevar a cabo
            estudios de mercado y estadísticas y actualizaciones para mejorar sus servicios. Sin embargo,
            puede oponerse, en cualquier momento, al procesamiento de sus Datos de contacto para el fin anteriormente
            indicado. Podrá realizar la solicitud de oposición enviando un correo
            electrónico a la siguiente dirección:
            <a href='mailto:encantosardientes@gmail.com'>encantosardientes@gmail.com</a>.
          </li>
          <li>
            <strong>Defensa de un derecho en el curso de procedimientos judiciales, administrativos o extrajudiciales y en
              el contexto de los litigios que surjan en relación con los servicios ofrecidos.
            </strong> El Dueño puede procesar sus Datos personales para defender sus derechos o actuar o incluso
            reclamar cualquier cosa contra usted o cualquier tercero. Base legal para el procesamiento:
            interés legítimo de El Dueño para defender sus derechos
            en los tribunales. Proporcionar sus Datos personales para tal fin es obligatorio; en caso de no hacerlo, El
            Dueño no podrá defender sus derechos.
          </li>
          <li>
            <strong>Finalidades relativas a las obligaciones previstas por la legislación argentina, por disposiciones de
              autoridades competentes y/o por organismos de supervisión y control.</strong> El Dueño puede procesar sus Datos personales para cumplir con las obligaciones a las que está
            sujeta. Bases legales para el procesamiento: obligación legal
            contemplada en los artículos 5º, inc. 2º, ap. b) y c), y
            11, inc. 3º, de la Ley 25.326 de Protección de los Datos Personales, es
            decir, tratamiento de datos por obligación legal, interés
            público o ejercicio de poderes públicos.Proporcionar sus Datos personales
            para tal fin es obligatorio; en caso de no hacerlo, El Dueño no podrá
            cumplir con determinadas obligaciones legales.
          </li>
          <li>
            En ningún caso, recopilamos, almacenamos o transferimos datos de su tarjeta.
            Las operaciones de pago se llevan a cabo íntegramente a travès del servidor seguro de la(s) pasarela(s) de pago y/o procesador(es) de pago.
            Julián Ramos no venderá, comprará, proporcionará, intercambiará ni de ninguna otra manera divulgará datos de Cuenta o Transacción,
            o información personal de o sobre un Titular de Tarjeta a nadie, excepto a su Adquirente, redes/asociaciones de Tarjetas o en respuesta a exigencias de las autoridades.
          </li>
        </ul>
        <br/>
        <p><strong>5. CÓMO MANTENEMOS SUS DATOS PERSONALES SEGUROS</strong></p>
        <p>El Dueño utiliza una amplia gama de medidas de seguridad para mejorar la protección y mantener la seguridad,
          integridad y accesibilidad de sus Datos personales.
        </p>
        <p>Todos sus Datos personales se almacenan en nuestros servidores seguros (o en copias en papel seguras u otros medios
          de almacenamiento duraderos) o en los de nuestros proveedores, y es posible acceder a ellos y utilizarlos en base a
          nuestros estándares y nuestras políticas de seguridad (o estándares equivalentes para nuestros proveedores).
        </p>
        <p><strong>6. DURANTE CUÁNTO TIEMPO ALMACENAMOS SU INFORMACIÓN</strong></p>
        <p>El Dueño conservará sus Datos personales solo durante el tiempo necesario para satisfacer los fines para los que
          fueron recopilados o cualquier otro propósito relacionado y lícito.
        </p>
        <p>Por lo tanto, si los Datos personales se procesan para dos propósitos diferentes, almacenaremos dichos Datos
          personales hasta que finalice el propósito del procesamiento que dure más tiempo; sin embargo, ya no los
          procesaremos para el propósito cuyo período de conservación haya finalizado.
        </p>
        <p>Cuando sus Datos personales ya no sean necesarios, o cuando ya no exista ninguna base legal para almacenarlos, se
          anonimizarán irreversiblemente o se destruirán de forma segura.
        </p>
        <p>A continuación se indican los períodos de conservación relativos a los diferentes fines descritos anteriormente:</p>
        <ul>
          <li>Finalidades estrictamente relacionadas con el acceso al Sitio web y su navegación por este, incluido el registro
            en el Sitio web: El Dueño almacenará los Datos personales que se procesen para tal fin durante el tiempo
            estrictamente necesario para proporcionarle acceso al Sitio web y en cualquier caso durante un máximo de 5 años
            después de su último acceso al Sitio web. Mantenemos los archivos de registro del servidor de los últimos 5
            años.
          </li>
          <li>Publicación de anuncios y posible respuesta a los mismos: El Dueño almacenará los Datos personales que se
            procesen para tal fin como máximo durante cinco (5) año tras la publicación del anuncio.
          </li>
          <li>Procesamiento de pedidos realizados a través del Sitio web y actividades relacionadas con la gestión de pedidos:
            los Datos personales procesados para cumplir con cualquier obligación contractual pueden almacenarse mientras el
            contrato esté vigente y, una vez finalizada la relación contractual, durante no más de diez (10) años.
          </li>
          <li>Propósitos de marketing para satisfacer sus necesidades o para informarle de promociones: El Dueño almacenará
            los Datos personales que se procesen para tal fin como máximo durante cinco (5) años a partir de la fecha de
            recopilación para este fin (excepto si previamente retira su consentimiento).
          </li>
          <li>Estudios de mercado y estadística para mejorar el servicio: El Dueño almacenará los Datos personales que se
            procesen para tal fin durante cinco (5) años a partir de su recopilación.
          </li>
          <li>Defensa de un derecho en el curso de procedimientos judiciales, administrativos o extrajudiciales y en el
            contexto de los litigios que surjan en relación con los servicios ofrecidos: El Dueño almacenará los Datos
            personales que se procesen para tal fin el tiempo estrictamente necesario para lograr dicho propósito.
          </li>
          <li>Finalidades relativas a las obligaciones previstas por la legislación argentina, por disposiciones de
            autoridades competentes y/o por organismos de supervisión y control: El Dueño almacenará los Datos personales
            que se procesen para tales fines el tiempo estrictamente necesario para lograr dichos propósitos
          </li>
        </ul>
        <br/>
        <p><strong>7. CON QUIÉN PODEMOS COMPARTIR SUS DATOS PERSONALES</strong></p>
        <p>Sin perjuicio de las comunicaciones realizadas en cumplimiento de las obligaciones legales, podrán acceder a sus
          Datos personales los empleados debidamente autorizados de El Dueño y los proveedores externos designados, cuando
          sea necesario, como encargados del procesamiento de datos, incluidos todos los proveedores de servicios informáticos
          y técnicos implicados en la prestación de los servicios ofrecidos por el Sitio web (incluidos, entre otros, los que
          proporcionan pasarelas de pago para pedidos realizados a través del Sitio web, etc.), cuya relación se encuentra a
          continuación:
        </p>
        <ul>
          <li>- MercadoPago;</li>
        </ul>
        <br/>
        <p>Al aceptar los términos de la presente Política de Privacidad, el usuario está prestando consentimiento expreso con
          el acceso y tratamiento de datos indicados en esta cláusula.
        </p>
        <p><strong>8. CONTACTOS</strong></p>
        <p>Los datos de contacto de El Dueño, en su calidad de controlador de datos, son los siguientes:</p>
        <p><strong>Julián Ramos</strong></p>
        <p>Dirección de correo electrónico: <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a></p>
        <p>
          <strong>9. SUS DERECHOS DE
            PROTECCIÓN DE DATOS PERSONALES Y SU DERECHO A PRESENTAR UNA QUEJA ANTE LAS AUTORIDADES DE CONTROL
          </strong>
        </p>
        <p>Usted tiene los siguientes derechos:</p>
        <ul>
          <li>El derecho de acceso a sus datos, que se ejercitará de acuerdo con lo establecido en el artículo 14 de la Ley
            25.326 de Protección de los Datos Personales;
          </li>
          <li>El derecho de acceso a una copia de los Datos personales que ha proporcionado (la llamada portabilidad de
            datos);
          </li>
          <li>El derecho a la rectificación de los Datos personales en nuestra posesión;</li>
          <li>El derecho a la supresión de cualquier Dato personal para el cual El Dueño ya no tenga un fundamento legal de
            procesamiento;
          </li>
          <li>El derecho a la retirada del consentimiento, cuando el procesamiento se base en el consentimiento;</li>
          <li>El derecho a solicitar la restricción de la forma en que El Dueño procesa sus Datos personales, en la medida
            permitida por la legislación vigente.
          </li>
        </ul>
        <br/>
        <p><strong>Derecho a oponerse:</strong>  además de los derechos anteriores, usted tiene derecho a oponerse en cualquier momento, por razones relacionadas
          con su situación particular, al procesamiento de los Datos personales que le conciernen llevado a cabo por la
          Dueño para la búsqueda de su interés legítimo.
        </p>
        <p>La solicitud de oposición debe remitirse a la siguiente dirección de correo electrónico: <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a>.</p>
        <p>El ejercicio de los derechos anteriores está sujeto a determinadas excepciones que tienen por objeto salvaguardar el
          interés público (por ejemplo, la prevención o identificación de delitos) y los intereses de El Dueño. Si desea
          ejercer cualquiera de los derechos anteriores, comprobaremos que tiene derecho a hacerlo y, por lo general, le
          responderemos en el plazo de un mes.
        </p>
        <p>Enero 2024</p>
      </div>
      </div>
    </main>
  )
}
