import React from 'react'
import SignIn from '../../components/SignIn/SignIn'
import SignUp from '../../components/SignUp/SignUp'
import PasswordChange from '../../components/PasswordChange/PasswordChange'
import { useSelector } from 'react-redux'

export default function Auth() {
  const storeAuthState = useSelector(store => store.authState.state)

  return (
    <>
      { storeAuthState === 'signin' && <SignIn/> }
      { storeAuthState === 'signup' && <SignUp/> }
      { storeAuthState === 'forgot' && <PasswordChange/> }
    </>
  )
}
