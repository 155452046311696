import React from 'react'
import './profile.css'
import { useState } from 'react';
import ProfileForm from '../../components/ProfileForm/ProfileForm';
import ProfileDisplay from '../../components/ProfileDisplay/ProfileDisplay';

export default function Profile() {
    const [has_posted, setHas_posted] = useState(JSON.parse(localStorage.getItem('user')).has_posted || false)

    return (
        <>
            {
                has_posted ?
                    <ProfileDisplay/>
                    :
                    <ProfileForm setHas_posted={setHas_posted} />
            }
        </>
    )
}
