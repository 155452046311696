import React, { useState } from 'react'
import './admin.css'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import toast from 'react-hot-toast'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(name, image, video) {
    return { name, image, video };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function Admin() {
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const token = localStorage.getItem('token')
    const headers = { headers: { 'Authorization': `Bearer ${token}` } }
    const [postsList, setPostsList] = useState([])
    console.log(postsList)
    useEffect(() => {
        if (!user.admin) {
            navigate('/')
        } else {
            getData()
        }
    }, [])

    async function getData() {
        try {
            const url = 'https://encantos-ardientes.onrender.com/admin/posts'
            const res = await axios.get(url, headers)
            setPostsList(res.data.posts)
        } catch (error) {
            if (error.message.includes('CORS')) {
                toast.error('Proba con otro archivo')
            } else {
                if (error.code === "ERR_NETWORK") {
                    toast.error("Network Error");
                } else {
                    if (error.response?.status === 401) {
                        toast.error('Algo salio mal')
                    } else {
                        if (typeof error.response.data.message === "string") {
                            toast.error(error.response.data.message);
                        } else {
                            error.response.data.message.forEach((err) => toast.error(err));
                        }
                    }
                }
            }
        }
    }

    return (
        <main className='adminContainer'>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Dessert (100g serving)</TableCell>
                            <TableCell align="right">Imágen</TableCell>
                            <TableCell align="right">video&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.image}</TableCell>
                                <TableCell align="right">{row.video}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </main>
    )
}
