import React from 'react'
import './passwordChange.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import authStateActions from '../../store/AuthState/actions'
import { LoadRemove, LoadStart } from '../Loading'

const { authState } = authStateActions

const defaultTheme = createTheme();

export default function PasswordChange() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    async function handleSubmit(event) {
        event.preventDefault()
        LoadStart()
        try {
            let url = "https://encantos-ardientes.onrender.com/auth/createrecoverymail"
            const data = new FormData(event.currentTarget);
            const userData = {
                email: data.get('email').trim(),
            }
            await axios.post(url, userData);
            toast.success('Revise su correo electrónico para restablecer su contraseña, puede tardar unos minutos');
            setTimeout(() => {
                changeToSignIn()
            }, 1500)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    function changeToSignIn() {
        dispatch(authState({ state: 'signin' }))
    }


    function goHome() {
        navigate('/')
    }

    return (
        <main className='passwordChangeContainer'>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Recuperar Contraseña
                                </Typography>
                            </Box>
                            <CloseIcon sx={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }} onClick={goHome} />
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Recuperá Tu Contraseña
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    );
}
