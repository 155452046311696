import React, { useEffect, useState } from 'react'
import axios from 'axios'
import toast from 'react-hot-toast'
import { Link as Anchor, useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { TextField } from '@mui/material'
import MultiSelect from '../../components/MultiSelect/MultiSelect'
import { Button } from '@mui/material'
import diamondVip from '../../images/diamante-vip.webp'
import diamond from '../../images/diamante.webp'
import platinum from '../../images/platino.webp'
import gold from '../../images/oro.webp'
import verified from '../../images/verificado.webp'
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme();

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions?.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

export default function MujerDisplay() {
    const navigate = useNavigate()
    const postsCategory = 'mujeres'
    const [filterURL, setFilterURL] = useState(Object.values(useParams()).join('/'))
    const [searchedName, setSearchedName] = useState('')
    const [virtual, setVirtual] = useState(false)
    const [filters, setFilters] = useState(`category=Mujer&city=${filterURL}`)
    const [postsArray, setPostsArray] = useState([])
    const [segmentos, setSegmentos] = useState([])
    const [city, setCity] = useState(Object.values(useParams()).join('/'))
    const [isOpen, setIsOpen] = useState(false);

    async function getData() {
        try {
            let url = `https://encantos-ardientes.onrender.com/post?${filters}`
            const res = await axios.get(url)
            setPostsArray(res.data.posts)
            getCity()
        } catch (error) {
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    function getCity() {
        if (filterURL) {
            var profileUrl = filterURL.split('/').map(function (segmento) {
                var palabras = segmento.split('-');
                palabras = palabras.map(function (palabra) {
                    return palabra.charAt(0).toUpperCase() + palabra.slice(1);
                });
                return palabras.join(' ');
            });
            setSegmentos(profileUrl)
        } else {
            setSegmentos(['Argentina'])
        }
    }

    function goToZone(e) {
        if (filterURL) {
            let string1 = filterURL;
            let string2 = e.target.text.toLowerCase().replace(' ', '-');
            var partes = string1.split("/");
            var index = partes.indexOf(string2);
            var resultado = partes.slice(0, index + 1).join("/");
            let userCategory = 'mujeres'
            if (string2 === 'argentina') {
                navigate(`/${userCategory}`)
            } else {
                navigate(`/${userCategory}/${resultado}`)
            }
            setFilterURL(resultado)
        }
    }

    useEffect(() => {
        setFilterURL(city)
        navigate(`/mujeres/${city}`)
    }, [city])

    useEffect(() => {
        const nameFilter = searchedName
        const zoneFilter = filterURL
        const wantVirtual = virtual
        setFilters(`name=${nameFilter}&category=Mujer&city=${zoneFilter}&virtual=${wantVirtual}`)
    }, [searchedName, filterURL, virtual])

    useEffect(() => {
        getData()
    }, [filters])

    function handleChange(e) {
        if (e.target.value === 'trans') {
            navigate(`/trans/${filterURL}`)
        }
        if (e.target.value === 'hombres') {
            navigate(`/hombres/${filterURL}`)
        }
    }

    function handleNameSearch(e) {
        setSearchedName(e.target.value)
    }

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    function handleVirtual(e) {
        setVirtual(e.target.checked)
    }


    return (
        <main className='postsContainer'>
            <div className='urlCardContainer'>
                {
                    segmentos?.map((urlSection, i) => {
                        return <section className='urlCard' key={i}>
                            <Anchor to='#' className='zonesUrl' onClick={goToZone}>{urlSection}</Anchor>
                            {segmentos.length - 1 > i && <KeyboardArrowRightIcon fontSize='5px' />}
                        </section>
                    })
                }
            </div>
            <div className='searchFilter'>
                <h3>Buscar Por:</h3>
                <div>
                    <TextField className='nameFilter'
                        id="outlined-required"
                        label="Nombre"
                        defaultValue=""
                        size='small'
                        onChange={handleNameSearch}
                    />
                    <div style={{ position: 'relative' }}>
                        {isOpen ? <Button variant='contained' onClick={handleButtonClick}>Cerrar</Button> : <Button variant='contained' onClick={handleButtonClick}>Zona</Button>}
                        {
                            isOpen &&
                            <div className='dropdown'>
                                <MultiSelect setCity={setCity} />
                            </div>
                        }
                    </div>
                    <div className='categorySelect'>
                        <select onChange={handleChange}>
                            <option value="mujeres">Mujeres</option>
                            <option value="trans">Trans</option>
                            <option value="hombres">Hombres</option>
                        </select>
                    </div>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography>Virtual</Typography>
                        <AntSwitch id='virtual' inputProps={{ 'aria-label': 'ant design' }} onChange={handleVirtual} />
                    </Stack>
                </div>
            </div>
            <section className='quickSearch'>
                <p>
                    <Anchor to='/mujeres/argentina/capital-federal' onClick={() => { setFilterURL('argentina/capital-federal') }}>Mujeres CABA</Anchor>/
                    <Anchor to='/mujeres/argentina/cordoba' onClick={() => { setFilterURL('argentina/cordoba') }}>Mujeres Cordoba</Anchor>/
                    <Anchor to='/mujeres/argentina/santa-fe/rosario' onClick={() => { setFilterURL('argentina/santa-fe/rosario') }}>Mujeres Rosario</Anchor>/
                    <Anchor to='/mujeres/argentina/mendoza' onClick={() => { setFilterURL('argentina/mendoza') }}>Mujeres Mendoza</Anchor>/
                    <Anchor to='/mujeres/argentina/tucuman' onClick={() => { setFilterURL('argentina/tucuman') }}>Mujeres Tucuman</Anchor>/
                    <Anchor to='/mujeres/argentina/buenos-aires/zona-norte' onClick={() => { setFilterURL('argentina/buenos-aires/zona-norte') }}>Mujeres Zona Norte</Anchor>/
                    <Anchor to='/mujeres/argentina/buenos-aires/zona-oeste' onClick={() => { setFilterURL('argentina/buenos-aires/zona-oeste') }}>Mujeres Zona Oeste</Anchor>/
                    <Anchor to='/mujeres/argentina/buenos-aires/zona-sur' onClick={() => { setFilterURL('argentina/buenos-aires/zona-sur') }}>Mujeres Zona Sur</Anchor>/
                    <Anchor to='/mujeres/argentina/buenos-aires/la-plata' onClick={() => { setFilterURL('argentina/buenos-aires/la-plata') }}>Mujeres La Plata</Anchor>/
                    <Anchor to='/mujeres/argentina/buenos-aires/mar-del-plata' onClick={() => { setFilterURL('argentina/buenos-aires/mar-del-plata') }}>Mujeres Mar del Plata</Anchor>
                </p>
            </section>
            {
                postsArray ?
                    postsArray.length ?
                        postsArray.map((postsGroup, i) => {
                            return <div key={i} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                {postsGroup._id === 5 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE VIP</h2><img className='categoryImg' src={diamondVip} alt='diamante'></img></div>}
                                {postsGroup._id === 4 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>DIAMANTE</h2><img className='categoryImg' src={diamond} alt='diamante'></img></div>}
                                {postsGroup._id === 3 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>PLATINO</h2><img className='categoryImg' src={platinum} alt='platino'></img></div>}
                                {postsGroup._id === 2 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>ORO</h2><img className='categoryImg' src={gold} alt='oro'></img></div>}
                                {postsGroup._id === 1 && <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}><h2 className='categoryTitle'>VERIFICADAS</h2><img className='categoryImg' src={verified} alt='verificadas'></img></div>}
                                {postsGroup._id === 0 && <h2 className='categoryTitle'>MUJERES</h2>}
                                <div className='grid'>
                                    {
                                        postsGroup.posts.map((post, i) => {
                                            return <Anchor className='grid-item' to={`/anuncio/${post.nameURL}`} key={i}>
                                                {
                                                    post.video ?
                                                        <video autoPlay loop muted src={post.video} alt={post.name}></video>
                                                        :
                                                        <img src={post.image} alt={post.name}></img>
                                                }
                                                <div className='cardTitle'>
                                                    <h2>{post.name}</h2>
                                                    <Anchor to={`/${postsCategory}/${post.city}`} onClick={() => { setFilterURL(post.city) }}>
                                                        {post.city
                                                            .split('/')
                                                            .pop()
                                                            .split('-')
                                                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                                                            .join(' ')
                                                        }
                                                    </Anchor>
                                                </div>
                                                {
                                                    post.virtual ? <div className='virtualBtn'>
                                                        Virtual
                                                    </div> : <></>
                                                }
                                            </Anchor>
                                        })
                                    }
                                </div>
                            </div>
                        }) : <h2 className='categoryTitle'>No se encontraron anuncios</h2>
                    : <></>
            }
        </main>
    )
}
