import React from 'react'
import './footer.css'
import { Container } from '@mui/material'
import { Link as Anchor } from 'react-router-dom'
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="/" target='_blank'>
                Encantos Ardientes
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Footer() {
    return (
        <footer>
            <Container className='termsContainer'>
                <Anchor to='/terminos-y-condiciones'>Términos y Condiciones</Anchor>
                <Anchor to='/politica-de-privacidad'>Política de Privacidad</Anchor>
                <Anchor to='/contactanos'>Contáctanos</Anchor>
                <Anchor to='/comprar-monedas'>Conseguí Monedas</Anchor>
            </Container>
            <Copyright/>
        </footer>
    )
}