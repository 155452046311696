import React from 'react'
import './header.css'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import Fade from '@mui/material/Fade';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import logo from '../../images/logo.webp'
import { useDispatch } from 'react-redux';
import navBarActions from '../../store/NavBarState/actions';
import NavBar from '../../components/NavBar/NavBar'
import { Link as Anchor } from 'react-router-dom';

const { navBarState } = navBarActions

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            '#back-to-top-anchor',
        );

        if (anchor) {
            anchor.scrollIntoView({
                block: 'center',
                behavior: 'smooth'
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function Header(props) {
    const dispatch = useDispatch()

    function openNavBar() {
        dispatch(navBarState({ state: 'open' }))
    }

    return (
        <React.Fragment>
            <Box sx={{ flexGrow: 1 }} id='back-to-top-anchor'>
                <AppBar position="static">
                    <Toolbar sx={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            <Anchor to='/' style={{ display: 'flex', alignItems: 'center', flexGrow: 1, textDecoration: 'none', color: '#EEE' }}>
                                <img className='logo' src={logo} alt='logo'></img>
                                <Typography variant="h6" component="h1" >
                                    Encantos Ardientes
                                </Typography>
                            </Anchor>
                        </div>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={openNavBar}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Box>
            <ScrollTop {...props}>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            <NavBar />
        </React.Fragment>
    );
}
