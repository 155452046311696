import React, { useState, useEffect } from 'react';
import data from '../../regions.json'; // Ajusta la ruta al archivo data.json según tu estructura de carpetas
import './multiSelect.css'

export default function MultiSelect({ setCity }) {
  const [provincias, setProvincias] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [barrios, setBarrios] = useState([]);
  const [selectedProvincia, setSelectedProvincia] = useState('');
  const [selectedLocalidad, setSelectedLocalidad] = useState('');
  const [selectedBarrio, setSelectedBarrio] = useState('');
  const [selectedRegionUrl, setSelectedRegionUrl] = useState('');
  const isForm = window.location.pathname

  useEffect(() => {
    setProvincias(data.filter(item => item.region_type === 'pub_province'));
  }, []);

  const getLocalidades = (provinciaId) => {
    return data.filter(item => item.region_type === 'pub_city' && item.region_parent === Number(provinciaId));
  };

  const getBarrios = (localidadId) => {
    return data.filter(item => item.region_type === 'pub_zone' && item.region_parent === Number(localidadId));
  };

  const handleProvinciaChange = (event) => {
    const selectedProvinciaId = event.target.value;
    const selectedProvincia = provincias.find(provincia => provincia.region_id === parseInt(selectedProvinciaId, 10));

    setSelectedProvincia(selectedProvinciaId);
    setSelectedLocalidad('');
    setSelectedBarrio('');
    setSelectedRegionUrl(selectedProvincia ? selectedProvincia.region_url : '');

    // Obtener las localidades correspondientes a la provincia seleccionada
    const localidadesProvincia = getLocalidades(selectedProvinciaId);
    setLocalidades(localidadesProvincia);

    // Limpiar la lista de barrios
    setBarrios([]);
  };

  const handleLocalidadChange = (event) => {
    const selectedLocalidadId = event.target.value;
    const selectedLocalidad = localidades.find(localidad => localidad.region_id === parseInt(selectedLocalidadId, 10));

    setSelectedLocalidad(selectedLocalidadId);
    setSelectedBarrio('');
    setSelectedRegionUrl(selectedLocalidad ? `${selectedLocalidad.region_url}` : '');
    setBarrios(getBarrios(selectedLocalidadId));
  };

  const handleBarriosChange = (event) => {
    const selectedBarrioId = event.target.value;
    const selectedBarrio = barrios.find(barrio => barrio.region_id === parseInt(selectedBarrioId, 10));

    setSelectedBarrio(selectedBarrioId);
    setSelectedRegionUrl(selectedBarrio ? `${selectedBarrio.region_url}` : '');
  };

  useEffect(() => {
    setCity(selectedRegionUrl)
  }, [selectedRegionUrl, setCity])

  return (
    <div>
      <select className='multiSelect' value={selectedProvincia} onChange={handleProvinciaChange}>
        <option value="" disabled>Selecciona una provincia</option>
        {provincias.map(provincia => (
          <option key={provincia.region_id} value={provincia.region_id}>{provincia.region_name}</option>
        ))}
      </select>
      {
        localidades.length ? <select className='multiSelect' value={selectedLocalidad} onChange={handleLocalidadChange} disabled={!selectedProvincia}>
          <option value="" disabled>Selecciona una localidad</option>
          {localidades.map(localidad => (
            <option key={localidad.region_id} value={localidad.region_id}>{localidad.region_name}</option>
          ))}
        </select> : <></>
      }
      {
        barrios.length ? <select className='multiSelect' value={selectedBarrio} onChange={handleBarriosChange} disabled={!selectedLocalidad}>
          <option value="" disabled>Selecciona un barrio</option>
          {barrios.map(barrio => (
            <option key={barrio.region_id} value={barrio.region_id}>{barrio.region_name}</option>
          ))}
        </select> : <></>
      }
      {
        barrios.length ?
          (isForm === '/editar-perfil' || isForm === '/perfil') ?
            <p className='nobarrio'>No es necesario seleccionar un barrio</p> : <></>
          : <></>
      }
    </div>
  );
}
