import React from 'react'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Outlet } from 'react-router-dom'
import { Toaster } from 'react-hot-toast';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import NotFoundPage from '../pages/NotFoundPage/NotFoundPage'

export default function TokenLayout() {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!token) {
            navigate('/')
        }
    })

    return (
        <>
            {
                token ?
                    <>
                        <Header />
                        <Outlet />
                        <Footer />
                        <Toaster position='top-left' toastOptions={{ duration: 4000 }} />
                    </>
                    : <NotFoundPage />
            }
        </>
    )
}
