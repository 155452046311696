import React from 'react'
import './contact.css'

export default function Contact() {
  return (
    <main className='contactContainer'>
      <div className="container mt-5">
        <h1 className="main-title page">Contáctanos</h1>
        <p>Para recibir asistencia técnica, puedes contactar con: <a href='mailto:encantosardientes@gmail.com'>encantosardientes@gmail.com</a></p>
        <p>
          Si tienes alguna duda sobre el uso ilícito de tus datos (como el correo electrónico o un número de teléfono) envía un correo electrónico
          a: <a href='mailto:encantosardientes@gmail.com'>encantosardientes@gmail.com</a>
        </p>
      </div>
    </main>
  )
}
