import React, { useState } from 'react'
import './profileImages.css'
import toast from 'react-hot-toast';
import AWS from 'aws-sdk'
import axios from 'axios';
import { Button } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import { LoadRemove, LoadStart } from '../Loading'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const s3 = new AWS.S3({
    region: "sa-east-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

export default function ProfileImages({ userData, wantDelete, setReload, reload, images, videos }) {
    const token = localStorage.getItem('token')
    let headers = { headers: { 'Authorization': `Bearer ${token}` } }
    const [wantImages, setwantImages] = useState(false)
    const [wantVideos, setwantVideos] = useState(false)
    const [openImage, setOpenImage] = useState(false);
    const handleCloseImage = () => setOpenImage(false);
    const [openVideo, setOpenVideo] = useState(false);
    const handleCloseVideo = () => setOpenVideo(false);
    const [imageIndex, setImageIndex] = useState('')
    const [videoIndex, setVideoIndex] = useState('')

    async function deleteImage(e) {
        LoadStart()
        try {
            const aws_key = e.target.id
            const url = `https://encantos-ardientes.onrender.com/postimage/delete/${aws_key}`
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: aws_key,
            };
            await s3.deleteObject(params).promise();
            const res = await axios.delete(url, headers)
            toast.success(res.data.message)
            setReload(!reload)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    async function deleteVideo(e) {
        LoadStart()
        try {
            const aws_key = e.target.id
            const url = `https://encantos-ardientes.onrender.com/postvideo/delete/${aws_key}`
            const params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                Key: aws_key,
            };
            await s3.deleteObject(params).promise();
            const res = await axios.delete(url, headers)
            toast.success(res.data.message)
            setReload(!reload)
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    function clickImage() {
        setwantImages(!wantImages)
        if (wantVideos) {
            setwantVideos(!wantVideos)
        }
    }

    function clickVideo() {
        setwantVideos(!wantVideos)
        if (wantImages) {
            setwantImages(!wantImages)
        }
    }

    function handleOpenImage(e) {
        setOpenImage(true);
        setImageIndex(Number(e.target.id))
    }

    function handleOpenVideo(e) {
        setOpenVideo(true);
        setVideoIndex(Number(e.target.id))
    }

    return (
        <>
            <div className='imagesAndVideoButtons'>
                <Button variant={wantImages ? 'contained' : 'outlined'} onClick={clickImage}><ImageIcon fontSize='small' /><p>FOTOS ({images.length})</p></Button>
                <Button variant={wantVideos ? 'contained' : 'outlined'} onClick={clickVideo}><VideoCameraBackIcon fontSize='small' /><p>VIDEOS ({videos.length})</p></Button>
            </div>
            {
                userData &&
                <div style={{ display: 'flex', flexDirection: 'column', gap: '1vh' }}>
                    <div className='imagesContainer'>
                        {
                            (wantImages || wantVideos) ?
                                <>
                                    {
                                        wantVideos ?
                                            videos.length ?
                                                videos.map((video, i) => {
                                                    return <div className='contentCard' key={i}>
                                                        <video src={video.video} alt={userData.name} id={i} onClick={handleOpenVideo}></video>
                                                        {
                                                            <div className='playButton'><PlayArrowIcon fontSize='large' id={i} onClick={handleOpenVideo} /></div>
                                                        }
                                                        {
                                                            wantDelete && <div className='deleteBtnContainer'><button id={video.aws_key} onClick={deleteVideo}>ELIMINAR</button></div>
                                                        }
                                                    </div>
                                                }) : <></>
                                            : <></>
                                    }
                                    {
                                        wantImages ?
                                            images.length ?
                                                images.map((image, i) => {
                                                    return <div className='contentCard' key={i}>
                                                        <img src={image.image} alt={userData.name} id={i} onClick={handleOpenImage}></img>
                                                        {
                                                            wantDelete && <div className='deleteBtnContainer'><button id={image.aws_key} onClick={deleteImage}>ELIMINAR</button></div>
                                                        }
                                                    </div>
                                                }) : <></>
                                            : <></>
                                    }
                                </>
                                :
                                <>
                                    {
                                        videos.length ?
                                            videos.map((video, i) => {
                                                return <div className='contentCard' key={i}>
                                                    <video src={video.video} alt={userData.name} id={i} onClick={handleOpenVideo}></video>
                                                    {
                                                        <div className='playButton'><PlayArrowIcon fontSize='large' id={i} onClick={handleOpenVideo} /></div>
                                                    }
                                                    {
                                                        wantDelete && <div className='deleteBtnContainer'><button id={video.aws_key} onClick={deleteVideo}>ELIMINAR</button></div>
                                                    }
                                                </div>
                                            }) : <></>
                                    }
                                    {
                                        images.length ?
                                            images.map((image, i) => {
                                                return <div className='contentCard' key={i}>
                                                    <img src={image.image} alt={userData.name} id={i} onClick={handleOpenImage}></img>
                                                    {
                                                        wantDelete && <div className='deleteBtnContainer'><button id={image.aws_key} onClick={deleteImage}>ELIMINAR</button></div>
                                                    }
                                                </div>
                                            }) : <></>
                                    }
                                </>
                        }
                    </div>
                </div>
            }
            <Modal
                open={openImage}
                onClose={handleCloseImage}
            >
                <Box className='contentModal'>
                    {imageIndex === 0 ? <div className='hiddenLeft'></div> : <ArrowBackIosIcon onClick={() => { setImageIndex(imageIndex - 1) }} className='left' />}
                    <img src={images[imageIndex]?.image} alt={userData.name}></img>
                    {imageIndex === images.length - 1 ? <div className='hiddenRight'></div> : <ArrowForwardIosIcon onClick={() => { setImageIndex(imageIndex + 1) }} className='right' />}
                    <CloseIcon className='closeModal' onClick={handleCloseImage} />
                </Box>
            </Modal>
            <Modal
                open={openVideo}
                onClose={handleCloseVideo}
            >
                <Box className='contentModal'>
                    {videoIndex === 0 ? <div className='hiddenLeft'></div> : <ArrowBackIosIcon onClick={() => { setVideoIndex(videoIndex - 1) }} className='left' />}
                    <video controls src={videos[videoIndex]?.video} alt={userData.name}></video>
                    {videoIndex === videos.length - 1 ? <div className='hiddenRight'></div> : <ArrowForwardIosIcon onClick={() => { setVideoIndex(videoIndex + 1) }} className='right' />}
                    <CloseIcon className='closeModal' onClick={handleCloseVideo} />
                </Box>
            </Modal>
        </>
    )
}
