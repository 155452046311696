import { createReducer } from "@reduxjs/toolkit";
import authStateActions from "./actions";

const {authState} = authStateActions

const initialState = {
    state: 'signin'
}

const authStateReducer = createReducer(
    initialState,
    (builder) => builder
        .addCase(
            authState,
            (state,action) => {
                let newState = {
                    ...state,
                    state: action.payload.state
                }
                return newState
            }
        )
)

export default authStateReducer