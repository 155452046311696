import React from 'react'
import './notFoundPage.css'
import { Button } from '@mui/material'
import { Link as Anchor } from 'react-router-dom'

export default function NotFoundPage() {
  return (
    <main className='notFoundContainer'>
        Página No Encontrada
        <Anchor to='/'><Button variant='contained'>VOLVER AL INICIO</Button></Anchor>
    </main>
  )
}
