import React from 'react'
import './forgotPassword.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { LoadStart, LoadRemove } from '../../components/Loading'

const defaultTheme = createTheme();

export default function ForgotPassword() {
    const navigate = useNavigate()

    const { userId } = useParams()

    // PASSWORD MATCH //
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
    // PASSWORD MATCH //

    async function handleSubmit(event) {
        event.preventDefault()
        LoadStart()
        try {
            let url = `https://encantos-ardientes.onrender.com/auth/updatepassword/${userId}`;
            const data = new FormData(event.currentTarget);
            const userData = {
                password: data.get('password'),
            }
            await axios.put(url, userData).then((res) => {
                toast.success(res.data.message);
                LoadRemove()
                setTimeout(() => {
                    navigate('/')
                }, 1500)
            });
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    function goHome() {
        navigate('/')
    }

    return (
        <main className='forgotPasswordContainer'>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Recuperar Contraseña
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Nueva Contraseña"
                                type="password"
                                id="password"
                                autoFocus
                                autoComplete="current-password"
                                onChange={handlePasswordChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Repetir Contraseña"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                onChange={handleConfirmPasswordChange}
                            />
                            {
                                password !== confirmPassword ?
                                    <>
                                        <p className='passwordMatch'>Las contraseñas no coinciden</p>
                                        <Button
                                            disabled
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 1, mb: 2 }}
                                        >
                                            Recuperar
                                        </Button>
                                    </>

                                    :
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Recuperar
                                    </Button>
                            }
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item>
                                    <Link variant="body2" onClick={goHome} sx={{ cursor: 'pointer' }}>
                                        {"Volver al Inicio"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    )
}
