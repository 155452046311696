import React from 'react'
import './profileForm.css'
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Select, MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import MultiSelect from '../../components/MultiSelect/MultiSelect'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import AWS from 'aws-sdk'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { LoadRemove, LoadStart } from '../Loading'
import { useRef } from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

const s3 = new AWS.S3({
    region: "sa-east-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});


const defaultTheme = createTheme();

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #bbb;

    &:hover {
      border-color: #222;
    }

    &:focus {
      border-color: rgb(25,118,210);
      box-shadow: 0 0 0 1px rgb(25,118,210);
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
)

export default function ProfileForm({ setHas_posted }) {
    const navigate = useNavigate()
    const [category, setCategory] = useState('')
    const [city, setCity] = useState('')
    const imageInput = useRef()
    const videoInput = useRef()
    const videoSelfieInput = useRef()

    function goHome() {
        navigate('/')
    }

    function handleCategory(event) {
        setCategory(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault()
        LoadStart()
        try {
            const imgFile = imageInput.current.files[0]
            const videoFile = videoInput.current?.files[0]
            const videoSelfieFile = videoSelfieInput.current?.files[0]
            const ahora = new Date();
            const dia = ahora.getDate().toString().padStart(2, '0');
            const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Nota: los meses son indexados desde 0
            const anio = ahora.getFullYear().toString().slice(-2);
            const hora = ahora.getHours().toString().padStart(2, '0');
            const minutos = ahora.getMinutes().toString().padStart(2, '0');
            const segundos = ahora.getSeconds().toString().padStart(2, '0');
            const resultado = `${dia}${mes}${anio}${hora}${minutos}${segundos}`;

            const virtual = document.getElementById('virtual')
            if (imgFile.type.includes('/png') || imgFile.type.includes('/jpg') || imgFile.type.includes('/jpeg') || imgFile.type.includes('/webp')) {
                if (videoSelfieFile.type.includes('/mp4') || videoSelfieFile.type.includes('/mov') || videoSelfieFile.type.includes('/3gp') || videoSelfieFile.type.includes('/avi')) {
                    if (videoFile) {
                        if (videoFile.type.includes('/mp4') || videoFile.type.includes('/mov') || videoFile.type.includes('/3gp') || videoFile.type.includes('/avi')) {
                            const data = new FormData(event.currentTarget);
                            const url = `https://encantos-ardientes.onrender.com/post/create`
                            const token = localStorage.getItem('token')
                            const userStorage = JSON.parse(localStorage.getItem('user'))
                            const headers = { headers: { 'Authorization': `Bearer ${token}` } }
                            const userData = {
                                name: data.get('name'),
                                category: category,
                                description: data.get('description'),
                                virtual: virtual.checked,
                                image: data.get('image'),
                                video: data.get('video'),
                                video_selfie: data.get('video_selfie'),
                                phone: `54${data.get('phone')}`.trim(),
                                city: city,
                                aws_key: ''
                            }
                            const photo = userData.image;
                            const photoName = `${resultado}${userData.image.name}`;
                            const imgParams = {
                                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                                Key: photoName,
                                Body: photo,
                            };
                            const imgResponseS3 = await s3.upload(imgParams).promise();
                            userData.image = imgResponseS3.Location
                            userData.aws_key = photoName

                            const video = userData.video;
                            const videoName = `${resultado}${userData.video.name}`;
                            const videoParams = {
                                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                                Key: videoName,
                                Body: video,
                            };
                            const videoResponseS3 = await s3.upload(videoParams).promise();
                            userData.video = videoResponseS3.Location
                            userData.video_aws_key = videoName

                            const videoSelfie = userData.video_selfie;
                            const videoSelfieName = `${resultado}${userData.video_selfie.name}`;
                            const videoSelfieParams = {
                                Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                                Key: videoSelfieName,
                                Body: videoSelfie,
                            };
                            const videoSelfieResponseS3 = await s3.upload(videoSelfieParams).promise();
                            userData.video_selfie = videoSelfieResponseS3.Location
                            userData.video_selfie_aws_key = videoSelfieName
                            const res = await axios.post(url, userData, headers)
                            localStorage.setItem('user', JSON.stringify({
                                ...userStorage,
                                has_posted: true
                            }))
                            const postData = res.data.post
                            localStorage.setItem('post', JSON.stringify({
                                ...postData
                            }))
                            setHas_posted(true)
                            toast.success(res.data.message)
                        } else {
                            toast.error('Tipo de video no permitido')
                        }
                    } else {
                        const data = new FormData(event.currentTarget);
                        const url = `https://encantos-ardientes.onrender.com/post/create`
                        const token = localStorage.getItem('token')
                        const userStorage = JSON.parse(localStorage.getItem('user'))
                        const headers = { headers: { 'Authorization': `Bearer ${token}` } }
                        const userData = {
                            name: data.get('name'),
                            category: category,
                            description: data.get('description'),
                            virtual: virtual.checked,
                            image: data.get('image'),
                            video_selfie: data.get('video_selfie'),
                            phone: `54${data.get('phone')}`.trim(),
                            city: city,
                            aws_key: ''
                        }
                        const photo = userData.image;
                        const photoName = `${resultado}${userData.image.name}`;
                        const params = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                            Key: photoName,
                            Body: photo,
                        };
                        const responseS3 = await s3.upload(params).promise();
                        userData.image = responseS3.Location
                        userData.aws_key = photoName

                        const videoSelfie = userData.video_selfie;
                        const videoSelfieName = `${resultado}${userData.video_selfie.name}`;
                        const videoSelfieParams = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                            Key: videoSelfieName,
                            Body: videoSelfie,
                        };
                        const videoSelfieResponseS3 = await s3.upload(videoSelfieParams).promise();
                        userData.video_selfie = videoSelfieResponseS3.Location
                        userData.video_selfie_aws_key = videoSelfieName
                        const res = await axios.post(url, userData, headers)
                        localStorage.setItem('user', JSON.stringify({
                            ...userStorage,
                            has_posted: true
                        }))
                        const postData = res.data.post
                        localStorage.setItem('post', JSON.stringify({
                            ...postData
                        }))
                        setHas_posted(true)
                        toast.success(res.data.message)
                    }
                } else {
                    toast.error('Tipo de video no permitido')
                }
            } else {
                toast.error('Tipo de imágen no permitida')
            }
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    return (
        <main className='profileContainer'>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <PersonAddIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Crea Tu Anuncio
                                </Typography>
                            </Box>
                            <CloseIcon sx={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }} onClick={goHome} />
                        </Box>
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }} encType="multipart/form-data">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Nombre de tu perfil"
                                name="name"
                                placeholder='Por Ej: Sofi Colegiala'
                                autoFocus
                            />
                            <FormControl fullWidth margin='normal'>
                                <InputLabel id="category-label">Categoria</InputLabel>
                                <Select
                                    labelId="category-label"
                                    id="category"
                                    value={category}
                                    label="category"
                                    onChange={handleCategory}
                                    required
                                >
                                    <MenuItem value='Mujer'>Mujer</MenuItem>
                                    <MenuItem value='Trans'>Trans</MenuItem>
                                    <MenuItem value='Hombre'>Hombre</MenuItem>
                                </Select>
                            </FormControl>
                            <MultiSelect setCity={setCity} />
                            <Textarea
                                aria-label="minimum height"
                                minRows={3}
                                id="description"
                                name="description"
                                placeholder="Descripción"
                            />
                            <p style={{ fontSize: '12px', fontWeight: 'bold' }}>¿Servicio Virtual?</p>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>No</Typography>
                                <AntSwitch id='virtual' inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Si</Typography>
                            </Stack>
                            <p style={{ fontSize: '12px', margin: '8px 0 3px 0', fontWeight: 'bold' }}>Subí tu imágen y si queres también un video de perfil</p>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                <h4>Imágen</h4>
                                <input ref={imageInput} className='inputImage' required accept="image/*" id="image" name='image' type="file" />
                                <h4>Video</h4>
                                <input ref={videoInput} className='inputImage' accept="video/*" id="video" name='video' type="file" />
                            </div>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '2px'
                                }}
                            >
                                <TextField
                                    margin='normal'
                                    sx={{ width: '4rem' }}
                                    disabled
                                    label="+54"
                                />
                                <TextField
                                    margin='normal'
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Número de Whatsapp (10 dígitos)"
                                    name="phone"
                                />
                            </Box>
                            <h5>Subi un video selfie para verificar tu identidad mostrando el frente de tu DNI</h5>
                            <input ref={videoSelfieInput} required className='inputImage' accept="video/*" id="video_selfie" name='video_selfie' type="file" />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 1, mb: 2 }}
                            >
                                Crea Tu Anuncio
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    )
}
