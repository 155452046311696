import React from 'react'
import './termsAndConditions.css'
import { Link as Anchor } from 'react-router-dom'

export default function TermsAndConditions() {
  return (
    <main className='termsAndConditionsContainer'>
      <div className="container mt-5"><div>
        <h1 className="main-title page">TÉRMINOS Y CONDICIONES DE USO</h1>
        <p>Actualización Enero 2024</p>
        <p>Los términos y condiciones a continuación detallados establecen un contrato de servicios de naturaleza civil y de
          adhesión, amparado en la autonomía de la voluntad de las partes, de conformidad a lo prescrito en el Código Civil el
          cual se constriñe a los siguientes términos.
        </p>
        <p>Declaro como usuario que he leído y conozco todos los Términos y Condiciones; por lo tanto, apruebo y acepto
          plenamente el contenido de este Reglamento, para hacer uso del sitio web ENCANTOSARDIENTES.COM; también declaro que toda la
          información que proporciono es real y corresponde a mis propios datos personales.
        </p>
        <p><strong>1. OBJETO</strong></p>
        <p>Encantosardientes.com es un sitio web (en adelante, "<strong>Encantos</strong>" o "Sitio") gestionado por Julián
          Ramos, que proporciona a los Usuarios, tal como se define en el&nbsp;<em>punto 3, un
            servicio de publicidad y consultor</em>ía para adultos (en adelante, “Publicidad”),
          dentro de los límites y condiciones establecidos con carácter general en este Reglamento (en
          adelante, “el Reglamento”) y, en particular, en las secciones correspondientes del Sitio
          Web.
        </p>
        <p><strong>2. TITULARIDAD DE ENCANTOS</strong></p>
        <p>Julián es el operador de Encantos, incluidos los contenidos relacionados, de la infraestructura web y/o accesible a
          través de dispositivos móviles así como, en todo caso, de cualesquiera derechos de propiedad relevantes relacionados
          con los derechos de propiedad intelectual y/o industrial. El servicio, logos y marcas, y todos los demás signos
          distintivos de Encantos están registrados en todo el mundo y su uso y/o explotación, en cualquier forma, queda
          prohibido. El tratamiento y protección de los datos personales, de los que Julián es titular, se rigen por
          nuestra&nbsp;<Anchor to="/politica-de-privacidad" target='_blank'>Política de privacidad</Anchor>.
        </p>
        <p><strong>3. APLICACIÓN DE LAS CONDICIONES</strong></p>
        <p>El Reglamento se aplica a todos los que utilizan el Sitio para publicar y/o consultar Anuncios (en adelante, los
          "Usuarios") y/o para interactuar con otros Usuarios.
        </p>
        <p><strong>4. MAYORÍA DE EDAD</strong></p>
        <p>El acceso al Sitio y la utilización de los servicios están reservados exclusivamente a los usuarios mayores de edad.
          El Usuario, en el momento de la publicación de un anuncio y, en cualquier caso, con motivo de cualquier otro uso del
          Sitio:
        </p>
        <ul>
          <li>Garantiza la exactitud, autenticidad y veracidad de todos los datos, y contenidos, las informaciones, imágenes
            y/o textos (en adelante, en su totalidad, los "<strong>Contenidos</strong>") insertados en el mismo, de los
            cuales asume plena responsabilidad;
          </li>
          <li>Especialmente certifica, bajo su propia responsabilidad, que ha cumplido los dieciocho años de edad;
          </li>
          <li>Se compromete a mantener dicho Contenido actualizado, modificándolo en caso de variaciones.</li>
        </ul>
        <br />
        <p>Las imágenes contenidas en los anuncios muestran personas mayores de edad que han manifestado su consentimiento para
          la publicación de sus imágenes. No publicamos conscientemente anuncios de menores o que los conciernan y respetamos
          íntegramente los derechos de los menores de acuerdo con lo dispuesto en la Convención sobre los Derechos del Niño
          (aprobada por Ley 23.849 y con jerarquía constitucional a partir de su incorporación a la Constitución Nacional en
          su art. 75, inc. 22) y en la Ley 26.061 de Protección Integral de los Derechos de las Niñas, Niños y Adolescentes.
        </p>
        <p>Excluyendo cualquier obligación a nuestro cargo, si Julián fuera consciente del hecho que un Usuario en realidad
          muestra y/o que un Anuncio concierne a un sujeto menor de 18 años (p.ej., tras ser informados por otro Usuario o por
          un tercero) y/o, en general, de la falsedad y/o incorreción, también parcial, del Contenido de un Anuncio, el
          Anuncio será eliminado mediante el procedimiento de borrado forense, así como cualquier otro Contenido atribuible al
          Usuario que lo ha publicado, incluido en su Cuenta, sin necesidad de preaviso. Julián podrá adoptar en cualquier
          momento las medidas adecuadas para la protección de los menores, incluyendo la notificación o denuncia a las
          autoridades competentes para que persigan la comisión de posibles delitos o infracciones cometidas por los Usuarios.
        </p>
        <p>Debido a que la red es un lugar de libertad, y al mismo tiempo de respeto, para proteger a los menores del acceso a
          imágenes explícitas aconsejamos usar un software de control parental como Cyber Patrol, Net Nanny, Surf Watch.
        </p>
        <p>Aunque sea mayor de edad, si considera que el Contenido destinado a un público adulto le puede incomodar, o ser
          contrario a su fe, a su moral o convicciones, no acceda al Sitio y no utilice los servicios.
        </p>
        <p><strong>5. USO DE ENCANTOS</strong></p>
        <p>
          <strong>Por favor, tenga en cuenta que este Sitio está dirigido estrictamente a adultos (mayores de 18 años) y al
            utilizarlo usted garantiza que es mayor de 18 años y que además, en el caso de los Usuarios Oferentes, no está
            ofreciendo ningún servicio a través del Sitio que contravenga la legislación local de su jurisdicción.
          </strong>
        </p>
        <p>El uso del Sitio es gratuito y permite la creación de una Cuenta, la publicación y/o la consulta de los Anuncios, y
          la interacción con otros usuarios. El usuario debe cumplir estrictamente las normas de uso del Sitio, de cualquier
          forma establecida y/o comunicada por Julián.
        </p>
        <p>Al hacer clic en el botón “Aceptar" al hacer una publicación en el Sitio, el Usuario ha aceptado que nos autoriza a
          procesar su fotografía con el fin de publicar un anuncio en el Sitio y que nos autoriza a procesar categorías
          especiales de sus datos personales (información que puede estar relacionada con su estilo de vida y comportamiento
          sexual) con el fin de publicar un anuncio en el Sitio.
        </p>
        <p>Con la solicitud de la publicación de un Anuncio, el Usuario acepta que Julián facilite que la publicación del
          Contenido sea visibile y accesible a otros Usuarios así como, también mediante una posterior indexación, automática
          o selectiva, en los motores de búsqueda y/u otros sitios web.
        </p>
        <p>Además, el Usuario reconoce y acepta que Julián promoverá todos los Anuncios en los mercados que considere
          relevantes. Sin embargo, los Usuarios no están autorizados a utilizar el Sitio, y los Contenidos y/o los Anuncios
          como su propia base de datos autónoma.
        </p>
        <p>Los Usuarios no están de ningún modo autorizados a utilizar el Sitio para fines distintos de los aquí indicados o
          aplicando formas o métodos que puedan potencialmente dañar, bloquear, cargar o perjudicar la funcionalidad de los
          servidores de Encantos y/o de las redes a las que esté conectado el Sitio. Cualquier de estas actividades puede dar
          lugar a la responsabilidad del Usuario por los daños y perjuicios causados.
        </p>
        <p>En particular, el Usuario se compromete a:</p>
        <ul>
          <li>No usar robots, spiders, “scrapers” o cualquier otro medio automático para acceder al Portal para copiar,
            retirar, renovar o publicar contenido sin previo consentimiento escrito por El Sitio;
          </li>
          <li>No almacenar o en cualquier forma recabar información o datos personales de terceros sin su consentimiento,
            incluidas las direcciones de correo electrónico;
          </li>
          <li>No esquivar, evitar o “saltar” las medidas utilizadas para impedir o restringir el acceso al Portal;</li>
          <li>No introducir virus, spyware, worms, trojans o malware de cualquier otro tipo que puedan afectar al
            funcionamiento del Sitio, tanto en su versión web como en su versión móvil.
          </li>
          <li>No alterar de ningún modo el funcionamiento del Sitio mediante el uso de instrumentos, dispositivos o software
            de cualquier tipo que puedan afectar entre otros, a la velocidad del Sitio o a su correcto funcionamiento, tanto
            en su versión web como en su versión móvil.
          </li>
        </ul>
        <br />
        <p><strong>Servicios de pago: Usuarios Oferentes</strong></p>
        <p>Algunas características especiales del Sitio están sujetas a pago y se rigen por las características específicas
          establecidas en las secciones del Sitio, fácilmente conocidas por cada Usuario que procede al pago. La adquisición
          de los servicios de pago estará registrada como pago a Encantos. El Usuario tiene un plazo de tres días para cancelar
          una compra o un servicio y ser reembolsado. Julián puede rechazar cualquier tipo de publicación, sin necesidad de
          motivar la decisión.
        </p>
        <p>Al publicar un anuncio en el Sitio (que incluye la creación de un perfil en línea), cada Usuario Oferente nos
          autoriza a promocionarlo para su mayor visibilidad a nuestra discreción y a operar el Servicio de manera que
          facilite la máxima eficacia en los mercados geográficos pertinentes. Al crear un perfil en línea o colocar un
          anuncio en el Sitio, los Usuarios Oferentes confirman que son mayores de edad en la jurisdicción correspondiente,
          que son la persona identificada en ese perfil y que no han sido coaccionados de ninguna manera para ofrecer
          servicios en el Sitio. <em></em>
        </p>
        <p>Los Usuarios son conscientes de que aceptamos el pago de los servicios a través de nuestro proveedor de
          pagos designado cuyos términos y condiciones rigen las transacciones entre los Usuarios y que dicho
          proveedor actúa como un proveedor de servicios independiente a este respecto. No somos responsables de los actos,
          omisiones o incumplimientos del proveedor de pagos.
        </p>
        <p>En caso de incumplimiento de estas condiciones, nos reservamos el derecho a retirar el anuncio sin reembolso, a
          eliminar cualquier perfil aplicable y a prohibir a los Usuarios la creación de futuros perfiles o la publicación de
          futuros anuncios.
        </p>
        <p><strong>Servicios de Pago: Monedas</strong></p>
        <p>
          Los Usuarios tienen la posibilidad de conseguir estas monedas las cuales están sujetas a pago y su cotización de compra es "1 Moneda = AR$100 (cien pesos argentinos)",
          <br /><br/><strong>Usuario Cliente:</strong>
          <ul>
            <li>Cuando este Usuario reciba las monedas en la cuenta, podrá obsequiarlas a un
              Usuario Oferente sin ánimos de obtener ningún Contenido a cambio ni nada parecido,
              el Usuario Oferente queda excento a cualquier pedido a cambio de estas monedas.
              Cualquier ofensa queda prohibida y puede llevar a la inhabilitación o borrado de la cuenta del Usuario Cliente.
            </li>
          </ul>
          <br /><strong>Usuario Oferente:</strong>
          <ul>
            <li>Cuando este Usuario reciba las monedas en la cuenta, podrá canjearlas para mejorar su categoría como oferente y mejorar su visibilidad en la página.
            </li>
          </ul>
          <br/>
          Por dudas o consultas sobre reembolsos de este servicio, ir a la sección "POLÍTICA DE REEMBOLSOS"
        </p>
        <em>
          <p><strong>6. RESPONSABILIDAD DEL USUARIO</strong></p>
          <p>Cada Usuario es responsable del uso de su propia Cuenta y debe informar de inmediato a Encantos de cualquier anomalía
            y/o uso no autorizado de su Cuenta, y también de terceros. Además, los Usuarios reconocen que son exclusivamente
            responsables, sea contra Julián sea contra otros Usuarios y/o de terceros, por los Anuncios y/o los Contenidos
            publicados y/o gestionados a través de sus Cuentas, así como del uso del Sitio, la veracidad, integridad y legalidad
            de los Anuncios y/o de las interacciones con otros Usuarios, comprometiéndose rigurosamente a que sus Anuncios y/o
            Contenido no resulten, en general, ílicitos y/o, de cualquier manera, no infrinjan el presente Reglamento.
          </p>
          <p>A título meramente ejemplificativo, pero no limitativo, el Usuario se compromete en todo caso a no publicar:</p>
          <ul>
            <li>Contenidos (y, especialmente, imágenes y/o textos) y/u otros elementos de naturaleza ofensiva.</li>
            <li>Contenido calumnioso y/o difamatorio.</li>
            <li>No se permiten anuncios que contengan referencias a servicios sexuales a cambio de dinero;</li>
            <li>Contenidos de pornografía infantil, pedofilia y/o referencias de cualquier tipo, incluso genéricas, a menores.</li>
            <li>Contenidos vulgares y obscenos.</li>
            <li>Contenidos blasfemos y/o discriminatorios contra terceros, de cualquier tipo (por ej. raciales, culturales o religiosos)</li>
            <li>Contenidos contrarios al orden público y las buenas costumbres.</li>
            <li>Anuncios con referencias a prácticas violentas o extremas.</li>
            <li>Contenidos y/o Anuncios relativos a la venta de fármacos, drogas, armas y/o instrumentos capaces de ofender.</li>
            <li>Anuncios con referencias, explícitas y/o implícitas, a servicios sexuales de pago.</li>
            <li>Anuncios no vinculados a la temática del sitio.</li>
            <li>Anuncios en idioma extranjero o en cualquier caso diferente a un idioma oficial del país.</li>
          </ul>
          <br />
          <p>El Usuario garantiza disponer de los Contenidos publicados sin violar derechos de terceros, tanto los derechos de
            imagen como los derechos de propiedad intelectual. La carga de la prueba de que los Contenidos no están protegidos
            por la legislación sobre la protección de los derechos intelectuales y/o industriales, en particular en materia de
            derechos de autor, recaerá sobre Usuario. Sin perjuicio de lo anterior, Julián podrá suspender y/o cancelar una
            Cuenta, sin necesidad de preaviso, sin incurrir en multas, cargos, reparaciones y/o sanciones de ningún tipo. A modo
            de ejemplo, una Cuenta podrá ser suspendida y/o eliminada en los siguientes casos, o análogos: violación del
            Reglamento; significativos indicios de violación de normas, en especial en materia de propiedad intelectual y/o
            industrial, contenido previamente usado, confidencialidad; inclusión de Contenido no autorizado, falso, inadecuado o
            como quiera que sea ilícito; violación de la netiquette.
          </p>
          <p>Julián se limita a ofrecer a los Usuarios los servicios informáticos presentes en el Sitio, en las condiciones
            previstas en el presente Reglamento, y no ejerce –ni está obligada a ejercer– algún control, preventivo y/o
            posterior, sobre los Anuncios y/o sobre Contenido, tampoco se lleva a cabo ningún tipo de intermediación en las
            transacciones entre los Usuarios que concluyan mediante el uso del Sitio. Por tanto, los Usuarios reconocen la
            completa inhibición de Julián respecto los Anuncios y/o a los Contenidos.
          </p>
          <p>Julián no facilita a los Usuarios otros servicios accesorios, como, por ejemplo, servicios fotográficos, de
            redacción y en la composición del Anuncio, de traducciones, correcciones y verificaciones del Contenido.
          </p>
          <p><strong>Directrices específicas de uso del Sitio</strong></p>
          <p>Para facilitar la identificación del contenido prohibido, a continuación se ofrece una lista no exhaustiva de
            contenidos que no pueden publicarse en el Sitio. Esta lista no puede considerarse ni ser utilizada como un criterio
            para determinar la legalidad de los contenidos que se desea incluir en los anuncios y perfiles.
          </p>
          <ul>
            <li>Se permiten las imágenes de desnudos, incluidas las que contienen genitales visibles, pero no se permite el
              material que contenga material que requiera un certificado PM18 o al que el Instituto Nacional de Cine y Artes
              Audiovisuales (INCAA) denegaría el certificado si se incluyera en un vídeo. Este material prohibido incluye:
            </li>
            <ul>
              <li>Imágenes de relaciones sexuales reales y no simuladas,</li>
              <li>Fuerte material fetichista,</li>
              <li>Imágenes animadas sexualmente explícitas, o</li>
              <li>Otras imágenes sexuales muy fuertes.</li>
            </ul>
            <li>No se permite ningún material que infrinja las normas sobre "pornografía extrema", incluido el material erótico
              que represente actos que sean o parezcan no consentidos, que impliquen o parezcan implicar animales, que
              impliquen o parezcan implicar actos sexuales con cadáveres o que representen actos que amenacen o parezcan
              amenazar la vida de una persona o actos que provoquen o puedan provocar lesiones graves en el ano, los pechos o
              los genitales de una persona;
            </li>
            <li>No se permite ningún material que haga uso sin el consentimiento de cada participante de material erótico que
              haya sido obtenido con ocasión de relaciones mantenidas en privado ("porno de venganza");
            </li>
            <li>No se permite ningún material que represente, promueva, induzca u ofrezca actividad sexual con menores o que
              promueva o fomente de alguna manera la pedofilia ("material pedopornográfico").
            </li>
            <li>La inserción en cualquier anuncio o perfil de material pedófilo será inmediatamente denunciada a las Autoridades
              competentes, incluyendo todos los accesos y metadatos, También nos reservamos el derecho de denunciar el
              material que contravenga nuestras normas sobre pornografía extrema o pornografía de venganza.
            </li>
            <li>No se permite ningún material que retrate o pretenda retratar a terceros que no hayan dado su consentimiento
              para ese uso o publicación.
            </li>
            <li>Al publicar anuncios o crear perfiles, los Usuarios certifican que tienen plenos derechos legales para hacerlo,
              y también declaran y garantizan que el material cargado pertenece a personas mayores de edad ( mayores de 18
              años) que han dado su consentimiento libre e informado para publicarlos en el Sitio con fines de citas para
              adultos y que no están siendo coaccionados o presionados de ninguna manera para participar;
            </li>
            <li>No está permitido incluir enlaces de hipertexto en el anuncio o perfil.</li>
          </ul>
          <p>Los Usuarios del Oferente no podrán cargar, enviar, mostrar o publicar material que constituya una incitación al
            odio, que sea discriminatorio, amenazante o acosador, o que fomente o promueva la violencia o que constituya un
            delito según la legislación relativa a la explotación sexual infantil, el tráfico de seres humanos, el terrorismo o
            el racismo o la xenofobia. A estos efectos, la "incitación al odio" incluye el material dirigido contra un grupo o
            individuo por motivos de raza, etnia, origen nacional, estatus migratorio, casta, religión, sexo, identidad o
            expresión de género, orientación sexual, edad, discapacidad o cualquier otra característica protegida y que
            pretenda, tenga o pueda tener el efecto de incitar a la discriminación, el odio, el miedo o la violencia contra
            dicho grupo o individuo.
          </p>
          <p>Los siguientes son ejemplos no exhaustivos de comportamientos o materiales que se consideran amenazantes o
            acosadores:
          </p>
          <ul>
            <li>Se dirige o parece dirigirse a un individuo o grupo de individuos específico y es ofensivo, despectivo o
              acosador;
            </li>
            <li>Se utilice o parezca que se va a utilizar para obtener dinero u otro beneficio de otra persona a cambio de la
              eliminación del Contenido;
            </li>
            <li>Contenga contenido sexual no solicitado o lenguaje no solicitado que cosifique sexualmente a otro Usuario o a
              cualquier otra persona de forma no consentida;
            </li>
            <li>Doxee o identifique de otro modo a un Usuario o a un tercero, o incluya material que pueda ser utilizado para
              tales fines, como datos del empleador, números de teléfono, detalles financieros, información de localización,
              nombres, documentos de identidad, direcciones de correo electrónico, credenciales de inicio de sesión o
              cualquier otra información de identificación personal sin el consentimiento expreso y por escrito de esa
              persona;
            </li>
          </ul>
          <br />
          <p>Los usuarios no pueden utilizar el Sitio para promover el comercio ilegal, los esquemas Ponzi o piramidales, ni para
            llevar a cabo una conducta engañosa o que pueda inducir a error o engañar a cualquier otro usuario o que implique,
            faltando a la realidad, que cualquier persona u organización respalda a ese usuario o su contenido o que está
            actuando como embajador de la marca o representante de cualquier persona u organización. El Sitio retirará cualquier
            material que sepa o tenga motivos para creer que infringe cualquiera de los puntos anteriores. Si desea denunciar
            cualquier material del Sitio que considere que infringe lo anterior, póngase en contacto con nosotros a través de <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a> o utilice el canal de denuncias.
          </p>
          <p>Está estrictamente prohibido utilizar este sitio web de cualquier forma que promueva o facilite la trata de personas,
            el tráfico sexual o el abuso físico. Si se identifica algún contenido que contenga indicadores de trata de personas o
            tráfico sexual, suspenderemos inmediatamente la cuenta y cooperaremos con las autoridades para ayudar a identificar
            a los perpetradores.
          </p>
          <p><strong>Canal de denuncias</strong></p>
          <p>Hemos incluido una función de "Denuncia de abuso" en el Sitio que permite a los Usuarios denunciar los anuncios que
            consideren que contravienen estos Términos y Condiciones de Uso, concretamente los que, en su opinión, contienen a)
            contenido de menores de edad; b) no han sido publicados por o con el consentimiento de la persona incluida en ellos;
            o c) son ilegales por otros motivos.
          </p>
          <p>Revisaremos todos los anuncios que se nos comuniquen y podremos estimar la queja y retirar el anuncio, exigir al
            Usuario que haya publicado el anuncio que proporcione material adicional que verifique su edad o la veracidad del
            contenido o los motivos que le permiten publicar el anuncio, según sea el caso, o rechazar la queja.
          </p>
          <p><strong>7. PROPIEDAD INTELECTUAL E INDUSTRIAL Y DERECHOS DE IMAGEN</strong></p>
          <p>El Sitio aloja contenidos tanto propios como contenidos creados por los usuarios. El Sitio está protegido por las
            leyes de propiedad intelectual y por los tratados internacionales en la materia. El contenido que se muestra en o a
            través del Sitio está protegido, ya sea de forma individual o en su condición de obra colectiva y / o compilación,
            de acuerdo con las leyes de propiedad intelectual y los tratados internacionales sobre la materia.
          </p>
          <p>Salvo que fuera autorizado por Julián o a menos que ello resulte legalmente permitido, el usuario no podrá copiar,
            modificar, distribuir, vender, alquilar o explotar de cualquier otra forma contenidos del Sitio (excepción hecha del
            propio contenido del usuario). Asimismo el usuario no puede llevar a cabo operaciones de desensamblaje o
            descompilación, ingeniería inversa o cualquier otra operación destinada a obtener cualquier código fuente contenido
            en este Sitio, salvo que esté expresamente permitido por la legislación aplicable, sin que sea posible excluirlo por
            contrato.
          </p>
          <p><strong>8. LIMITACIONES DE LA RESPONSABILIDAD</strong></p>
          <p>Julián proporciona el Sitio tal y como está, sin ninguna garantía y/o aptitud del uso, y el Usuario –plenamente
            conocedor de la accesibilidad pública a las páginas del Sitio así, como de todos los Anuncios y/o de los Contenidos–
            asume todos los riesgos derivados de su uso, también del impropio, y/o de la posibilidad de la difusión de los
            propios datos personales mediante la publicación de Anuncios.
          </p>
          <p>Julián no es responsable de la veracidad, exactitud e integridad de los Anuncios, ni de su posible oposición al
            orden público, a las buenas costumbres y a la moral, ni de la violación de los derechos de propiedad intelectual e
            industrial o de imagen de terceros.
          </p>
          <p>En cualquier caso, Julián no responderá de los posibles daños directos y/o indirectos, contractuales y/o
            extracontractuales, patrimoniales o no, causado por parte de los Usuarios y otros Usuarios y/o a terceros en
            relación a los Contenidos, y a los Anuncios y/o, debido al uso del Sitio y/o de los servicios relacionados.
          </p>
          <p>La responsabilidad de Encantos o el Usuario no podrá excluirse en aquellos supuestos en que la Ley no lo permita.
          </p>
          <p>El Sitio y sus afiliados, así como sus sociedades filiales o matrices, gerentes, empleados, agentes o administradores
            no aceptan ninguna responsabilidad por:
          </p>
          <ul>
            <li>Cualquier pérdida o daño en la medida en que se derive de circunstancias ajenas a su control razonable,</li>
            <li>Cualquier pérdida o daño causado por cualquier interrupción o falta de disponibilidad del Sitio o del Servicio,
            </li>
            <li>Cualquier pérdida directa o indirecta de beneficios, negocios, reputación, ahorros previstos o volumen de
              negocio,</li>
            <li>Cualquier pérdida o corrupción de datos,</li>
            <li>Cualquier pérdida indirecta, especial punitiva o de cualquier otro tipo, incluyendo daños indirectos o
              consecuentes, aunque se le haya advertido de la posibilidad de tales pérdidas.
            </li>
          </ul>
          <p>Tenga en cuenta que Encantos no interfiere en la negociación entre las partes.
          </p>
          <p></p>
          <p><strong>9. LIMITACIONES EN LA PRESTACIÓN DEL SERVICIO</strong></p>
          <p>El presente Reglamento se refiere exclusivamente al Sitio, y a los servicios relativos, en el estado tecnológico y
            jurídico resultante al momento de la aceptación y modificaciones posteriores. Julián no ofrece ninguna garantía de
            idoneidad para un propósito particular, tampoco es capaz de garantizar –y se abstiene de prometer– resultados
            específicos derivados del uso del Sitio.
          </p>
        </em>
        <p><em>En consideración de las características del Sitio y de las relativas al servicio, Julián no garantiza que el
          funcionamiento sea ininterrumpido o libre de defectos (a modo de ejemplo:&nbsp;<em>bug, incompatibilidad con
            espec</em>í</em>fico&nbsp;software&nbsp;o&nbsp;hardware, etc.). En particular, Julián no puede ser
          considerada responsable de problemas o errores técnicos relativos a: redes o líneas
          telefónicas; sistemas informáticos,
          también&nbsp;on-line&nbsp;y/o&nbsp;cloud,&nbsp;server&nbsp;y/o&nbsp;provider; equipo
          informático;&nbsp;software, no o mal funcionamiento de programas de correo electrónico o de
          reproducción de audio/video causados por problemas técnicos o congestión del
          tráfico en Internet.
        </p>
        <p>Julián se reserva el derecho de modificar, suspender o interrumpir los servicios del Sitio, en su totalidad o
          parcialmente, en cualquier momento, sin preaviso&nbsp;y sin tener que justificar las razones de la limitación.
        </p>
        <p>Por ello, el Usuario no podrá responsabilizar a Julián, de ninguna manera, por un mal funcionamento del Sitio y/o
          por la falta de la prestación de los servicios, incluso si este mal funcionamento pueda causar errores y/o retrasos
          en relación a los Anuncios y/o a los Contenidos.
        </p>
        <p><strong>10. POLÍTICA DE REEMBOLSOS</strong></p>
        <p>Como se indicó anteriormente, el usuario tiene un plazo de tres días para cancelar una compra o un servicio y ser reembolsado.
        </p>
        <p>En particular (pero sin limitación):</p>
        <ul>
          <li>Nos reservamos el derecho a intervenir a través del Sitio o a realizar cualquier modificación o cambio (por
            ejemplo, de diseño, de enfoque y/o de contenido funcional) en el mismo sin notificación previa, o a limitar el
            acceso del Usuario Oferente al sitio web por razones de mantenimiento o técnicas o de otro tipo durante un
            periodo de tiempo necesario sin notificación previa. En tales casos, ningún Usuario Oferente tendrá derecho a
            reclamar ninguna compensación o reembolso en virtud de esta política basada en dicha modificación, cambios o no
            disponibilidad.
          </li>
          <li>Si nuestro proveedor de pagos recibe una solicitud de una institución financiera o de una plataforma para que se
            le devuelva cualquier cantidad financiera gastada en compras de Servicios de Pago, nos reservamos el derecho de
            bloquear el Perfil del Usuario Oferente al recibir dicha solicitud hasta que el proveedor de pagos nos informe
            de que el problema se ha resuelto completamente a satisfacción del proveedor de pagos y/o de la institución
            financiera o de la plataforma.
          </li>
        </ul>
        <br />
        <p><strong>Reembolsos:</strong> La política establece que los montos pagados por Servicios en general no
          incluyen reembolsos para los Usuarios. Esto significa que, una vez que haya pagado
          por un servicio, no tendrá derecho a un reembolso.
        </p>
        <p><strong>Crédito Excepcional:</strong> La política contempla algunas excepciones, en las cuales la
          empresa puede, a su exclusiva discreción, ofrecer crédito adicional. Este crédito
          puede utilizarse para futuros Servicios de Pago. Sin embargo, es importante tener en
          cuenta que esto no es un derecho automático, sino una medida discrecional.
        </p>
        <p><strong>Cambios en el Sitio y Mantenimiento:</strong>  La empresa se reserva el derecho de realizar
          cambios o modificaciones en el sitio web, incluyendo el diseño, el enfoque y el
          contenido funcional, sin previo aviso. Además, puede limitar el acceso de los
          Usuarios al sitio por motivos de mantenimiento o razones técnicas. En tales casos,
          los Usuarios no tienen derecho a compensación o reembolsos basados en estos cambios,
          modificaciones o indisponibilidad temporal.
        </p>
        <p><strong>Bloqueo de Perfiles de Usuario: </strong> Si los proveedores de pago de la empresa reciben una
          solicitud de reembolso de una institución financiera y/o de otra plataforma, la
          empresa se reserva el derecho de bloquear el perfil del Usuario. Cualquier desbloqueo
          estará a discreción exclusiva de la empresa.
        </p>
        <p><strong>11. VALIDEZ DE LOS TÉRMINOS Y CONDICIONES PARA EL USO DEL SERVICIO</strong></p>
        <p>En cualquier momento Julián puede modificar unilateralmente el presente Reglamento y/o los demás términos y
          condiciones para el uso del Sitio. En particular, en caso de modificaciones no descritas en el Reglamento, Julián
          comunicará dichas modificaciones a los Usuarios de la manera que considere adecuada (p. ej.., mediante pop-up,
          e-mail, etc.). Tras la recepción de dicha comunicación, constituirá a todos los efectos la aceptación del
          Reglamento. No obstante lo anterior, las modificaciones realizadas por Julián al Reglamento entrarán en vigor el
          tercer día siguiente a la publicación en el Sitio.
        </p>
        <p><strong>12. INDEMNIZACIÓN</strong></p>
        <p>Cada Usuario (aún compareciendo ante un tribunal y permitiendo el cese) exonerará a Julián, y sus administradores,
          trabajadores y/o colaboradores, por cualquier motivo, de cualquier solicitud de resarcimiento, indemnización y/o
          cualquier responsabilidad, incluyendo costos razonables de defensa, relacionado con una denuncia de otros Usuarios
          y/o terceros en relación al uso del Sitio, y/o con los servicios relacionados, y/o con los Contenidos publicados y/o
          consultados. Cada Usuario reconoce beneficiarse de una ventaja singular, y de un valor añadido con el uso del Sitio
          y/o de los relativos servicios, así como de los costos soportados por Julián por su creación, mantenimiento y
          gestión. Por todo lo expuesto, el Usuario declara y garantiza a Julián su intención de exonerarle de cualquier
          responsabilidad por las cuestiones antedichas.
        </p>
        <p><strong>13. LEY APLICABLE Y JURISDICCIÓN</strong></p>
        <p>El presente Reglamento se rige por lo dispuesto en las leyes de la República Argentina. En caso de controversias,
          serán competentes los tribunales del domicilio del Usuario.
        </p>
        <p><strong>14. MISCELÁNEA</strong></p>
        <p>Una eventual invalidez o ineficacia de cualquiera de las previsiones del presente Reglamento, en todo caso, dejarán
          intactos el resto de las mismas.</p>
        <p>Si una disposición del presente Reglamento fuese declarada o considerada ílicita, inaplicable o nula, la misma deberá
          ser corregida modificando dicha disposición para que sea legalmente válida y aplicable preservando su fin, y si esto
          no fuese posible, sustituyéndola con otra que sea válida y aplicable logrando el mismo objetivo.</p> <p>El presente Reglamento continuará siendo aplicable a los Usuarios, incluso después de la cancelación de la Cuenta,
            para todas aquellas cláusulas que originen obligaciones destinadas a sobrevivir al cese de su efectividad entre las
            partes.
        </p>
        <p>Todas las posibles modificaciones objetivas y/o subjetivas del presente Reglamento, así como de sus posibles anexos,
          deberán ser expresamente acordadas por escrito.
        </p>
        <p>Para cualquier solicitud escribir a <a href="mailto:encantosardientes@gmail.com">encantosardientes@gmail.com</a>.</p>
        <p><strong>Encantos Ardientes<br />Julián Ramos<br />Código de Identificación Fiscal: 20-42684156-9</strong></p>
      </div>
      </div>
    </main>
  )
}
