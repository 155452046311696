import React from 'react'
import './navBar.css'
import { Link as Anchor } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import authStateActions from '../../store/AuthState/actions'
import navBarActions from '../../store/NavBarState/actions';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import toast from 'react-hot-toast';
import axios from 'axios';
import GroupIcon from '@mui/icons-material/Group';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LoadRemove, LoadStart } from '../Loading'

const { navBarState } = navBarActions
const { authState } = authStateActions

export default function NavBar() {
    const dispatch = useDispatch()
    const navBarStateStore = useSelector(store => store.navBarState.state)
    const token = localStorage.getItem('token')
    const userStorage = JSON.parse(localStorage.getItem('user'))

    function handleSignIn() {
        dispatch(authState({ state: 'signin' }))
        closeNavBar()
    }

    function handleSignUp() {
        dispatch(authState({ state: 'signup' }))
        closeNavBar()
    }

    async function handleSignOut() {
        LoadStart()
        try {
            let url = 'https://encantos-ardientes.onrender.com/auth/signout'
            let headers = { headers: { 'Authorization': `Bearer ${token}` } }
            await axios.post(url, null, headers).then(res => {
                localStorage.setItem('token', '')
                localStorage.setItem('user', JSON.stringify({
                    admin: '',
                    name: '',
                    photo: '',
                    has_posted: '',
                    email: '',
                    coins: '',
                    loginAt: ''
                }))
                toast.success(res.data.message)
                closeNavBar()
            })
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (error.response?.status === 401) {
                    toast.error('Algo salio mal')
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            }
        }
    }

    function closeNavBar() {
        dispatch(navBarState({ state: 'closed' }))
    }


    return (
        <div
            className={`
                ${navBarStateStore === 'open' && 'containerNavStore'}
                ${navBarStateStore === 'closed' && 'containerNavStoreClosed'}
            `}
        >
            <div className="anchorsContainer">
                <Box className='blueBar' sx={{ flexGrow: 1, width: '100%', display: 'flex', justifyContent: 'end' }} id='back-to-top-anchor' >
                    <AppBar className='navBarHeader' position="static">
                        <Toolbar sx={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                            <IconButton
                                size="large"
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={closeNavBar}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Box>
                <Button fullWidth className='navItem'>
                    <Anchor to='/' className="buttonAnchor" onClick={closeNavBar}>
                        <HomeIcon />
                        Inicio
                    </Anchor>
                </Button>
                {
                    token ?
                        userStorage.has_posted ?
                            <hr style={{ width: '88%' }} /> : <></> : <></>
                }
                {
                    token ?
                        userStorage.has_posted ?
                            <Button fullWidth className='navItem'>
                                <Anchor to={'/perfil'} className="buttonAnchor" onClick={closeNavBar}>
                                    <PersonAddIcon />
                                    Gestiona Tu Anuncio
                                </Anchor>
                            </Button>
                            : <Button fullWidth className='navItem'>
                                <Anchor to={'/perfil'} className="buttonAnchor" onClick={closeNavBar}>
                                    <PersonAddIcon />
                                    Publica Tu Anuncio
                                </Anchor>
                            </Button>
                        : <></>
                }
                {
                    token ?
                        userStorage.has_posted ?
                            <Button fullWidth className='navItem'>
                                <Anchor to={'/editar-perfil'} className="buttonAnchor" onClick={closeNavBar}>
                                    <PersonAddIcon />
                                    Edita Tu Anuncio
                                </Anchor>
                            </Button>
                            : <></>
                        : <></>
                }
                <hr style={{ width: '88%' }} />
                <Button fullWidth className='navItem'>
                    <Anchor to={'/mujeres'} className="buttonAnchor" onClick={handleSignUp}>
                        <GroupIcon />
                        Encontrá Mujeres
                    </Anchor>
                </Button>
                <Button fullWidth className='navItem'>
                    <Anchor to={'/trans'} className="buttonAnchor" onClick={handleSignUp}>
                        <GroupIcon />
                        Encontrá Trans
                    </Anchor>
                </Button>
                <Button fullWidth className='navItem'>
                    <Anchor to={'/hombres'} className="buttonAnchor" onClick={handleSignUp}>
                        <GroupIcon />
                        Encontrá Hombres
                    </Anchor>
                </Button>
                {token ? <hr style={{ width: '88%' }} /> : <></>}
                {
                    token ?
                        <Button fullWidth className='navItem'>
                            <Anchor to={'/comprar-monedas'} className="buttonAnchor" onClick={closeNavBar}>
                                <MonetizationOnIcon />
                                {
                                    userStorage.has_posted ? 'Mejorar Anuncio' : 'Comprar Monedas'
                                }
                            </Anchor>
                        </Button>
                        : <></>
                }
                {
                    token ? <Button fullWidth className='navItem' variant='contained' sx={{ width: '88%', color: 'white', cursor: 'pointer' }}>
                        <MonetizationOnIcon />
                        {userStorage.coins} Monedas
                    </Button> : <></>
                }
                <hr style={{ width: '88%' }} />
                {

                    token ? <></> :
                        <Button fullWidth className='navItem'>
                            <Anchor to={'/auth'} className="buttonAnchor" onClick={handleSignUp}>
                                <LoginIcon />
                                Registrarse
                            </Anchor>
                        </Button>
                }
                {
                    token ? <></> :
                        <Button fullWidth className='navItem'>
                            <Anchor to={'/auth'} className="buttonAnchor" onClick={handleSignIn}>
                                <LoginIcon />
                                Iniciar Sesión
                            </Anchor>
                        </Button>
                }
                <div className="logOutHelp">
                    {
                        token ?
                            <Button fullWidth className='navItem'>
                                <Anchor className="buttonAnchor" onClick={handleSignOut}>
                                    <LogoutIcon />
                                    Cerrar Sesión
                                </Anchor>
                            </Button> : <></>
                    }
                </div>
            </div>
        </div>
    )
}
