import React from 'react'
import './editProfile.css'
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd'
import { Select, MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import MultiSelect from '../../components/MultiSelect/MultiSelect'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import AWS from 'aws-sdk'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import axios from 'axios';
import { LoadStart, LoadRemove } from '../../components/Loading'
import { useRef } from 'react';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';

const s3 = new AWS.S3({
    region: "sa-east-1",
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

const defaultTheme = createTheme();

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const Textarea = styled(BaseTextareaAutosize)(
    ({ theme }) => `
    box-sizing: border-box;
    width: 320px;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid #bbb;

    &:hover {
      border-color: #222;
    }

    &:focus {
      border-color: rgb(25,118,210);
      box-shadow: 0 0 0 1px rgb(25,118,210);
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
)

export default function EditProfile() {
    const postData = JSON.parse(localStorage.getItem('post'))
    const navigate = useNavigate()
    const [category, setCategory] = useState(postData?.category)
    const [city, setCity] = useState('')
    const imageInput = useRef()
    const videoInput = useRef()
    const [permition, setPermition] = useState(false)

    function goProfile() {
        navigate('/perfil')
    }

    function handleCategory(event) {
        setCategory(event.target.value);
    }

    async function handleSubmit(event) {
        event.preventDefault()
        LoadStart()
        try {
            const ahora = new Date();
            const dia = ahora.getDate().toString().padStart(2, '0');
            const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Nota: los meses son indexados desde 0
            const anio = ahora.getFullYear().toString().slice(-2);
            const hora = ahora.getHours().toString().padStart(2, '0');
            const minutos = ahora.getMinutes().toString().padStart(2, '0');
            const segundos = ahora.getSeconds().toString().padStart(2, '0');
            const resultado = `${dia}${mes}${anio}${hora}${minutos}${segundos}`;
            const imgFile = imageInput.current.files[0]
            const videoFile = videoInput.current?.files[0]
            const virtual = document.getElementById('virtual')
            const url = `https://encantos-ardientes.onrender.com/post/update`
            const token = localStorage.getItem('token')
            const headers = { headers: { 'Authorization': `Bearer ${token}` } }
            const data = new FormData(event.currentTarget);
            let userData = {
                name: data.get('name'),
                category: category,
                description: data.get('description'),
                virtual: virtual.checked,
                image: data.get('image'),
                phone: `54${data.get('phone')}`,
                city: city,
                aws_key: postData.aws_key,
                age: data.get('age'),
                height: data.get('height'),
                dotation: data.get('dotation') || '0',
                hair_color: data.get('hair_color'),
                eyes_color: data.get('eyes_color'),
                depilation: data.get('depilation'),
                body_type: data.get('body_type'),
                tits_type: data.get('tits_type') || postData.tits_type,
                ass_type: data.get('ass_type'),
                work_hours: data.get('work_hours'),
            }
            if (postData.video) {
                userData.video = postData.video
                userData.video_aws_key = postData.video_aws_key
            }
            if (!userData.city) {
                userData.city = postData.city
            }
            if (userData.image.name) {
                if (imgFile.type.includes('/png') || imgFile.type.includes('/jpg') || imgFile.type.includes('/jpeg') || imgFile.type.includes('/webp')) {
                    const deleteParams = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: userData.aws_key,
                    };
                    await s3.deleteObject(deleteParams).promise();

                    const photo = userData.image;
                    const photoName = `${resultado}${userData.image.name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: photoName,
                        Body: photo,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    userData.image = responseS3.Location
                    userData.aws_key = photoName
                } else {
                    LoadRemove()
                    toast.error('Tipo de imágen no permitida')
                }
            } else {
                userData.image = postData.image
            }
            if (videoFile) {
                if (videoFile.type.includes('/mp4') || videoFile.type.includes('/mov') || videoFile.type.includes('/3gp') || videoFile.type.includes('/avi')) {
                    userData.video = data.get('video')
                    if (postData.video) {
                        const deleteParams = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                            Key: postData.video_aws_key,
                        };
                        await s3.deleteObject(deleteParams).promise();
                    }
                    const video = userData.video;
                    const videoName = `${resultado}${userData.video.name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: videoName,
                        Body: video,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    userData.video = responseS3.Location
                    userData.video_aws_key = videoName
                    setPermition(true)
                } else {
                    LoadRemove()
                    toast.error('Tipo de video no permitido')
                }
            }
            if (userData.image.name && videoFile) {
                if ((imgFile.type.includes('/png') || imgFile.type.includes('/jpg') || imgFile.type.includes('/jpeg') || imgFile.type.includes('/webp')) && (videoFile.type.includes('/mp4') || videoFile.type.includes('/mov') || videoFile.type.includes('/3gp') || videoFile.type.includes('/avi'))) {
                    const res = await axios.put(url, userData, headers)
                    const postStorage = res.data.post
                    localStorage.setItem('post', JSON.stringify({
                        ...postStorage
                    }))
                    toast.success(res.data.message)
                    LoadRemove()
                    navigate('/perfil')
                }
            } else if (userData.image.name || videoFile) {
                if ((imgFile?.type.includes('/png') || imgFile?.type.includes('/jpg') || imgFile?.type.includes('/jpeg') || imgFile?.type.includes('/webp')) || (videoFile?.type.includes('/mp4') || videoFile?.type.includes('/mov') || videoFile?.type.includes('/3gp') || videoFile?.type.includes('/avi'))) {
                    const res = await axios.put(url, userData, headers)
                    const postStorage = res.data.post
                    localStorage.setItem('post', JSON.stringify({
                        ...postStorage
                    }))
                    toast.success(res.data.message)
                    LoadRemove()
                    navigate('/perfil')
                }
            } else {
                const res = await axios.put(url, userData, headers)
                const postStorage = res.data.post
                localStorage.setItem('post', JSON.stringify({
                    ...postStorage
                }))
                toast.success(res.data.message)
                LoadRemove()
                navigate('/perfil')
            }
        } catch (error) {
            console.log(error)
            LoadRemove()
            if (error.message.includes('CORS')) {
                toast.error('Proba con otro archivo')
            } else {
                if (error.code === "ERR_NETWORK") {
                    toast.error("Network Error");
                } else {
                    if (error.response?.status === 401) {
                        toast.error('Algo salio mal')
                    } else {
                        if (typeof error.response.data.message === "string") {
                            toast.error(error.response.data.message);
                        } else {
                            error.response.data.message.forEach((err) => toast.error(err));
                        }
                    }
                }
            }
        }
    }

    async function asd(event) {
        event.preventDefault()
        LoadStart()
        try {
            const ahora = new Date();
            const dia = ahora.getDate().toString().padStart(2, '0');
            const mes = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Nota: los meses son indexados desde 0
            const anio = ahora.getFullYear().toString().slice(-2);
            const hora = ahora.getHours().toString().padStart(2, '0');
            const minutos = ahora.getMinutes().toString().padStart(2, '0');
            const segundos = ahora.getSeconds().toString().padStart(2, '0');
            const resultado = `${dia}${mes}${anio}${hora}${minutos}${segundos}`;
            const imgFile = imageInput.current.files[0]
            const virtual = document.getElementById('virtual')

            const data = new FormData(event.currentTarget);
            const url = `https://encantos-ardientes.onrender.com/post/update`
            const token = localStorage.getItem('token')
            const headers = { headers: { 'Authorization': `Bearer ${token}` } }
            let userData = {
                name: data.get('name'),
                category: category,
                description: data.get('description'),
                virtual: virtual.checked,
                image: data.get('image'),
                phone: `54${data.get('phone')}`,
                city: city,
                aws_key: postData.aws_key,
                age: data.get('age'),
                height: data.get('height'),
                dotation: data.get('dotation') || '0',
                hair_color: data.get('hair_color'),
                eyes_color: data.get('eyes_color'),
                depilation: data.get('depilation'),
                body_type: data.get('body_type'),
                tits_type: data.get('tits_type') || postData.tits_type,
                ass_type: data.get('ass_type'),
                work_hours: data.get('work_hours'),
            }
            if (postData.video) {
                userData.video = postData.video
                userData.video_aws_key = postData.video_aws_key
            }
            if (!userData.city) {
                userData.city = postData.city
            }
            if (userData.image.name) {
                if (imgFile.type.includes('/png') || imgFile.type.includes('/jpg') || imgFile.type.includes('/jpeg') || imgFile.type.includes('/webp')) {
                    const deleteParams = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: userData.aws_key,
                    };
                    await s3.deleteObject(deleteParams).promise();

                    const photo = userData.image;
                    const photoName = `${resultado}${userData.image.name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: photoName,
                        Body: photo,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    userData.image = responseS3.Location
                    userData.aws_key = photoName
                    setPermition(true)
                } else {
                    toast.error('Tipo de imágen no permitida')
                }
            } else {
                userData.image = postData.image
                setPermition(true)
            }
            const videoFile = videoInput.current?.files[0]
            if (videoFile) {
                if (videoFile.type.includes('/mp4') || videoFile.type.includes('/mov') || videoFile.type.includes('/3gp') || videoFile.type.includes('/avi')) {
                    userData.video = data.get('video')
                    if (postData.video) {
                        const deleteParams = {
                            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                            Key: postData.video_aws_key,
                        };
                        await s3.deleteObject(deleteParams).promise();
                    }
                    const video = userData.video;
                    const videoName = `${resultado}${userData.video.name}`;
                    const params = {
                        Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
                        Key: videoName,
                        Body: video,
                    };
                    const responseS3 = await s3.upload(params).promise();
                    userData.video = responseS3.Location
                    userData.video_aws_key = videoName
                    setPermition(true)
                } else {
                    toast.error('Tipo de video no permitido')
                }
            }
            if (permition) {
                const res = await axios.put(url, userData, headers)
                const postStorage = res.data.post
                localStorage.setItem('post', JSON.stringify({
                    ...postStorage
                }))
                toast.success(res.data.message)
                LoadRemove()
                navigate('/perfil')
            }
            LoadRemove()
        } catch (error) {
            LoadRemove()
            if (error.message.includes('CORS')) {
                toast.error('Proba con otro archivo')
            } else {
                if (error.code === "ERR_NETWORK") {
                    toast.error("Network Error");
                } else {
                    if (error.response?.status === 401) {
                        toast.error('Algo salio mal')
                    } else {
                        if (typeof error.response.data.message === "string") {
                            toast.error(error.response.data.message);
                        } else {
                            error.response.data.message.forEach((err) => toast.error(err));
                        }
                    }
                }
            }
        }
    }

    return (
        <>
            {
                postData &&
                <main className='editProfileContainer'>
                    <ThemeProvider theme={defaultTheme}>
                        <Container component="main" maxWidth="xs">
                            <CssBaseline />
                            <Box
                                sx={{
                                    marginTop: 4,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                            <PersonAddIcon />
                                        </Avatar>
                                        <Typography component="h1" variant="h5">
                                            Edita Tu Anuncio
                                        </Typography>
                                    </Box>
                                    <CloseIcon sx={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }} onClick={goProfile} />
                                </Box>
                                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1, width: '100%' }} encType="multipart/form-data">
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="name"
                                        label="Nombre de tu perfil"
                                        name="name"
                                        placeholder='Por Ej: Sofi Colegiala'
                                        defaultValue={postData.name}
                                    />
                                    <FormControl fullWidth margin='normal'>
                                        <InputLabel id="category-label">Categoria</InputLabel>
                                        <Select
                                            labelId="category-label"
                                            id="category"
                                            value={category}
                                            label="category"
                                            onChange={handleCategory}
                                            required
                                        >
                                            <MenuItem value='Mujer'>Mujer</MenuItem>
                                            <MenuItem value='Trans'>Trans</MenuItem>
                                            <MenuItem value='Hombre'>Hombre</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <p style={{ fontSize: '14px' }}><b>Seleccionada</b>: {postData.city}</p>
                                    <MultiSelect setCity={setCity} />
                                    <Textarea
                                        aria-label="minimum height"
                                        minRows={3}
                                        id="description"
                                        name="description"
                                        placeholder="Descripción"
                                        defaultValue={postData.description}
                                    />
                                    <p style={{ fontSize: '12px', fontWeight: 'bold' }}>¿Servicio Virtual?</p>
                                    {
                                        postData.virtual ?
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>No</Typography>
                                                <AntSwitch id='virtual' defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                                <Typography>Si</Typography>
                                            </Stack>
                                            :
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>No</Typography>
                                                <AntSwitch id='virtual' inputProps={{ 'aria-label': 'ant design' }} />
                                                <Typography>Si</Typography>
                                            </Stack>
                                    }
                                    <p style={{ fontSize: '12px', margin: '8px 0 3px 0', fontWeight: 'bold' }}>Subí tu imágen y si queres también un video de perfil</p>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '5px', marginBottom: '8px' }}>
                                        <h4>Imágen</h4>
                                        <input ref={imageInput} className='inputImage' accept="image/*" id="image" name='image' type="file" />
                                        <h4>Video</h4>
                                        <input ref={videoInput} className='inputImage' accept="video/*" id="video" name='video' type="file" />
                                    </div>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '2px'
                                        }}
                                    >
                                        <TextField
                                            margin='normal'
                                            sx={{ width: '4rem' }}
                                            disabled
                                            label="+54"
                                        />
                                        <TextField
                                            margin='normal'
                                            required
                                            fullWidth
                                            id="phone"
                                            label="Número de Whatsapp (10 dígitos)"
                                            name="phone"
                                            placeholder='Número de Whatsapp (10 dígitos)'
                                            defaultValue={Number(postData.phone.toString().substring(2))}
                                        />
                                    </Box>
                                    <TextField
                                        margin='normal'
                                        required
                                        fullWidth
                                        id="age"
                                        label="Edad"
                                        name="age"
                                        placeholder='Edad'
                                        defaultValue={postData.age}
                                    />
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="height"
                                        label="Altura"
                                        name="height"
                                        placeholder='Altura'
                                        defaultValue={postData.height}
                                    />
                                    {
                                        (category === 'Trans' || category === 'Hombre') &&
                                        <TextField
                                            margin='normal'
                                            fullWidth
                                            id="dotation"
                                            label="Dotación"
                                            name="dotation"
                                            placeholder='Dotación'
                                            defaultValue={postData.dotation}
                                        />
                                    }
                                    <TextField
                                        margin='normal'

                                        fullWidth
                                        id="hair_color"
                                        label="Color de Cabello"
                                        name="hair_color"
                                        placeholder='Cabello'
                                        defaultValue={postData.hair_color}
                                    />
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="eyes_color"
                                        label="Color de Ojos"
                                        name="eyes_color"
                                        placeholder='Ojos'
                                        defaultValue={postData.eyes_color}
                                    />
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="depilation"
                                        label="Depilación"
                                        name="depilation"
                                        placeholder='Depilación'
                                        defaultValue={postData.depilation}
                                    />
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="body_type"
                                        label="Cuerpo"
                                        name="body_type"
                                        placeholder='Cuerpo'
                                        defaultValue={postData.body_type}
                                    />
                                    {
                                        (category === 'Trans' || category === 'Mujer') &&
                                        <TextField
                                            margin='normal'
                                            fullWidth
                                            id="tits_type"
                                            label="Busto"
                                            name="tits_type"
                                            placeholder='Busto'
                                            defaultValue={postData.tits_type}
                                        />
                                    }
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="ass_type"
                                        label="Cola"
                                        name="ass_type"
                                        placeholder='Cola'
                                        defaultValue={postData.ass_type}
                                    />
                                    <TextField
                                        margin='normal'
                                        fullWidth
                                        id="work_hours"
                                        label="Horario"
                                        name="work_hours"
                                        placeholder='Horario'
                                        defaultValue={postData.work_hours}
                                    />
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 1, mb: 2 }}
                                    >
                                        Edita Tu Anuncio
                                    </Button>
                                </Box>
                            </Box>
                        </Container>
                    </ThemeProvider>
                </main>
            }
        </>
    )
}
