import { createAction } from "@reduxjs/toolkit";

let navBarState = createAction(
    'navBarState',
    ({state}) => {
        return {
            payload: {
                state: state
            }
        }
    }
)

const navBarActions = {navBarState}
export default navBarActions