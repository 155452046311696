import React from 'react'
import './signUp.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import CloseIcon from '@mui/icons-material/Close';
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import toast from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import authStateActions from '../../store/AuthState/actions'
import { LoadRemove, LoadStart } from '../Loading'

const { authState } = authStateActions

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();

googleAuthProvider.setCustomParameters({
    prompt: 'select_account', // Esto forzará el selector de cuentas
});

const defaultTheme = createTheme();

export default function SignUp() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // PASSWORD MATCH //
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
    // PASSWORD MATCH //

    function handleGoogleSignUp() {
        LoadStart()
        let url = "https://encantos-ardientes.onrender.com/auth/googlesignup";
        signInWithPopup(auth, googleAuthProvider)
            .then(async (result) => {
                const user = result.user;
                const tempUsername = user.displayName.split(' ')
                let username = tempUsername[0]
                if (tempUsername[1]) {
                    username = tempUsername[0] + (tempUsername[1].charAt(0))
                }
                let userData = {
                    name: username,
                    email: user.email,
                    password: user.uid,
                    photo: user.photoURL,
                };
                await axios.post(url, userData).then((res) => {
                    localStorage.setItem("token", res.data.token);
                    localStorage.setItem(
                        "user",
                        JSON.stringify({
                            id: res.data.user._id,
                            name: res.data.user.name,
                            email: res.data.user.email,
                            photo: res.data.user.photo,
                            coins: res.data.user.coins,
                            admin: res.data.user.is_admin,
                            has_posted: res.data.user.has_posted,
                            loginAt: res.data.user.loginAt
                        })
                    );
                    toast.success(res.data.message);
                    LoadRemove()
                    setTimeout(() => {
                        navigate('/')
                    }, 1500);
                });
            })
            .catch((error) => {
                LoadRemove()
                if (error.code === "ERR_NETWORK") {
                    toast.error("Network Error");
                } else {
                    if (typeof error.response.data.message === "string") {
                        toast.error(error.response.data.message);
                    } else {
                        error.response.data.message.forEach((err) => toast.error(err));
                    }
                }
            });
    }

    async function handleSignUp(event) {
        event.preventDefault()
        LoadStart()
        let url = "https://encantos-ardientes.onrender.com/auth/signup"
        const data = new FormData(event.currentTarget);
        const userData = {
            name: data.get('name'),
            email: data.get('email').trim(),
            password: data.get('password'),
        }
        try {
            const res = await axios.post(url, userData);
            toast.success(res.data.message);
            LoadRemove()
            setTimeout(() => {
                changeToSignIn()
            }, 1500)
        } catch (error) {
            LoadRemove()
            if (error.code === "ERR_NETWORK") {
                toast.error("Network Error");
            } else {
                if (typeof error.response.data.message === "string") {
                    toast.error(error.response.data.message);
                } else {
                    error.response.data.message.forEach((err) => toast.error(err));
                }
            }
        }
    }

    function changeToSignIn() {
        dispatch(authState({ state: 'signin' }))
    }

    function goHome() {
        navigate('/')
    }

    return (
        <main className='signInContainer'>
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                    <LockOutlinedIcon />
                                </Avatar>
                                <Typography component="h1" variant="h5">
                                    Registrarse
                                </Typography>
                            </Box>
                            <CloseIcon sx={{ position: 'absolute', top: '0', right: '0', cursor: 'pointer' }} onClick={goHome} />
                        </Box>
                        <Box component="form" onSubmit={handleSignUp} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="name"
                                label="Usuario"
                                name="name"
                                autoFocus
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Contraseña"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={handlePasswordChange}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="confirmPassword"
                                label="Repetir Contraseña"
                                type="password"
                                id="confirmPassword"
                                autoComplete="current-password"
                                onChange={handleConfirmPasswordChange}
                            />
                            {
                                password !== confirmPassword ?
                                    <>
                                        <p className='passwordMatch'>Las contraseñas no coinciden</p>
                                        <Button
                                            disabled
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 1, mb: 2 }}
                                        >
                                            Registrarse
                                        </Button>
                                    </>

                                    :
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Registrarse
                                    </Button>
                            }
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 0, mb: 2, display: 'flex', alignItems: 'center', gap: '5px' }}
                                onClick={handleGoogleSignUp}
                            >
                                <GoogleIcon fontSize='inherit' sx={{ margin: '0 0 1px 0' }} /> Ingresa Con Google
                            </Button>
                            <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
                                <Grid item>
                                    <Link variant="body2" onClick={changeToSignIn} sx={{ cursor: 'pointer' }}>
                                        {"Ya tenes cuenta? Inicia Sesión"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </main>
    );
}
